
import React, {Component} from 'react';
import {Layout} from "antd";
const {Header} = Layout;

export default class Headbar extends Component {

    render() {
        return (
            <Header style={{ background: '#fff', padding: 10 , height:500}} >&nbsp;</Header>
        )
    }
}
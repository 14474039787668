import axios from './axios';

class GenerateTokenService {
    static Instance() {
        return new GenerateTokenService()
    }

    generateToken(userId, roleId,days) {
        return axios.get('/api/admin/generatetoken?userId=' + userId + '&roleId=' + roleId +'&days='+days);
    }
}

export default GenerateTokenService.Instance()
import React, { Component } from "react";
import {Card, Icon} from 'antd';
export default class Timer extends Component {
    constructor(props) {
      super(props);
      this.state = {date: ""};
    }
  
    componentDidMount() {
        this.timerId = setInterval(() => this.getTime(),
            1000
          );
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
      }
  
    getTime(){
        const date = new Date()
         let month = date.getMonth() + 1;
         let year = date.getFullYear();
         let day = date.getDate()
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        if(seconds<10) {
            seconds = `0${seconds}`
        }
        if(day<10) {
            day = `0${day}`
        }
        if(minutes<10) {
            minutes = `0${minutes}`
        }
        if(month<10) {
            month = `0${month}`
        }
        if(hours<10) {
            hours = `0${hours}`
        }
        let timer = (`${month}-${day}-${year} | ${hours}:${minutes}:${seconds}`)
        this.setState({
            date: timer
          });
    }
  
    render() {
      return (
        <Card>
         <div> <h3 style={{textAlign:"end"}}> <Icon style={{marginRight:"10px"}} type="calendar" />{this.state.date}</h3></div>
        </Card>
      );
    }
  }

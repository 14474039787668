import React, { useEffect, useState } from "react";
import ParkingManagementService from "../../services/ParkingManagementService";
import { Button, Col, Form, Input, Row, Table, message, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import CommonService from "../../services/CommonService";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";
const { Option } = Select;

export default function CompanyView() {

    const [companies, setCompanies] = useState([])
    const [showCreateCompany, setShowCreateCompany] = useState(false)

    useEffect(() => {
        getCompanies()
    }, [])

    function getCompanies() {
        ParkingManagementService.getCompanies().then(response => {
            setCompanies(response.data)
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    function deleteCompany(id) {
        ParkingManagementService.deleteCompany(id).then(response => {
            message.info("Delete ok")
            getCompanies()
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Reserved slots',
            dataIndex: 'reservedSlots',
            key: 'reservedSlots',
        },
        {
            title: 'Building',
            dataIndex: 'building',
            key: 'building',
        }, {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span onClick={() => deleteCompany(record.id)}>
                    <a>Delete</a>
                </span>
            ),
        },
    ]

    function onCreateCompany() {
        setShowCreateCompany(true)
    }

    function hideCreateCompany() {
        setShowCreateCompany(false)
    }

    const AddCompanyForm = Form.create({ name: 'company_form' })(CreateCompanyView);

    return (<Row>

      <Heading>Companies</Heading>
        <Timer/>
        <Col style={{marginTop:"30px"}} lg={20} offset={2}>
            {
                showCreateCompany && <AddCompanyForm onchange={getCompanies} onhide={hideCreateCompany} />
            }
            {
                !showCreateCompany && <Button style={{ marginBottom: "20px" }} type="primary" onClick={onCreateCompany}>
                    New Company
                    <PlusCircleOutlined />
                </Button>
            }
            <Table dataSource={companies} columns={columns} rowKey="id" />
        </Col>
    </Row>)

}

function CreateCompanyView(props) {
    const { onchange } = props;
    const [buildings, setBuildings] = useState([])

    useEffect(() => {
        getBuildings()
    }, [])

    function getBuildings() {
        ParkingManagementService.getBuildings().then(response => {
            setBuildings(response.data)
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }
    function handleSubmit(e) {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                ParkingManagementService.createCompany(values).then(response => {
                    message.info("Created successfully")
                    onchange()
                }).catch(error => {
                    message.error(CommonService.handleErrorMessage(error));
                })

            } else {
                message.info("Missing values!")
            }
        });
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;
    const nameError = isFieldTouched('name') && getFieldError('name');
    const codeError = isFieldTouched('code') && getFieldError('code');
    const buildingError = isFieldTouched('building') && getFieldError('building');
    const reservedSlotsError = isFieldTouched('reservedSlots') && getFieldError('reservedSlots');


    return <div>
        <Form layout="inline" onSubmit={handleSubmit} disabled={hasErrors(getFieldsError())}>
            <Form.Item validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Enter name of company' }],
                })(
                    <Input
                        placeholder="Name"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={codeError ? 'error' : ''} help={codeError || ''}>
                {getFieldDecorator('code', {
                    rules: [{ required: true, message: 'Enter short code for company' }],
                })(
                    <Input
                        placeholder="Code"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={buildingError ? 'error' : ''} help={buildingError || ''}>
                {getFieldDecorator('building', {
                    rules: [{ required: true, message: 'Enter Company' }],
                })(
                    <Select style={{ width: 120 }} >
                        {buildings.map(building => {
                            return <Option value={building.id}>{building.name}</Option>
                        })}

                    </Select>,
                )}
            </Form.Item>

            <Form.Item validateStatus={reservedSlotsError ? 'error' : ''} help={reservedSlotsError || ''}>
                {getFieldDecorator('reservedSlots', {
                    rules: [{ required: true, message: 'Enter # of Reserved Slots in building' }],
                })(
                    <Input
                        placeholder="Reserved Slots"
                    />,
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Add</Button>
            </Form.Item>
        </Form>
    </div>
}
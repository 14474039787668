import React, { Component } from 'react'
import { Button, Card, Col, message, Row, Switch, PageHeader, Spin, Input } from 'antd'
import VidsService from "../../services/VidsService"
import CommonService from "../../services/CommonService"

export default class VidsSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vidsSetting: {},
            loading: false,
            saving: false,
            formError: {}
        }

        this.refresh = this.refresh.bind(this);
        this.saveVidsSetting = this.saveVidsSetting.bind(this);
    }
    componentDidMount() {
        // this.refresh()
    }
    refresh() {
        this.setState({ loading: true });
        VidsService.getVidsSettings().then(res => {
            this.setState({ vidsSetting: res.data, loading: false });
        }).catch(e => {
            this.setState({ loading: false });
            message.error(CommonService.handleErrorMessage(e));
        })
    }
    handleChange(value, name) {
        let { vidsSetting } = this.state;
        vidsSetting[name] = value;
        this.setState({ vidsSetting });
    }
    saveVidsSetting() {
        let { vidsSetting, formError } = this.state;
        if (vidsSetting.autoRefresh && (!vidsSetting.autoRefreshInSeconds || vidsSetting.autoRefreshInSeconds === '0')) {
            formError.autoRefreshInSeconds = "Enter auto refresh in seconds";
            this.setState({ formError });
            return;
        }
        this.setState({ saving: true, formError: {} });
        VidsService.saveVidsSettings(vidsSetting).then(res => {
            this.setState({ vidsSetting: res.data, saving: false });
            message.success("Saved!")
        }).catch(e => {
            this.setState({ saving: false });
            message.error(CommonService.handleErrorMessage(e));
        })
    }
    render() {
        const { vidsSetting, loading, formError } = this.state;

        return (
            <div>
                <br />
                <br />
                <Row>
                    <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 4 }} sm={{ span: 2 }} xs={{ span: 2 }} />
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <Card title={<div align={"center"}>VIDS Settings</div>} >
                            {loading ? <div align={"center"}><Spin /></div> :
                                <div>
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Auto refresh : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={vidsSetting.autoRefresh}
                                                onChange={val => this.handleChange(val, 'autoRefresh')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Auto refresh in seconds : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}>
                                                <Input
                                                    name={'autoRefreshInSeconds'}
                                                    type="number"
                                                    placeholder="Enter auto refresh in seconds"
                                                    value={vidsSetting.autoRefreshInSeconds}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                />
                                                <div className="text-danger">{formError.autoRefreshInSeconds}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Speed visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={vidsSetting.speedVisible}
                                                onChange={val => this.handleChange(val, 'speedVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Direction visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={vidsSetting.directionVisible}
                                                onChange={val => this.handleChange(val, 'directionVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Image visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={vidsSetting.imageVisible}
                                                onChange={val => this.handleChange(val, 'imageVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Date & Time visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={vidsSetting.dateTimeVisible}
                                                onChange={val => this.handleChange(val, 'dateTimeVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Location visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={vidsSetting.locationVisible}
                                                onChange={val => this.handleChange(val, 'locationVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Screenshot visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={vidsSetting.screenshotVisible}
                                                onChange={val => this.handleChange(val, 'screenshotVisible')} />
                                            </div>
                                        </Col>
                                    </Row><br />

                                </div>}
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
import React from "react";
import { Typography } from 'antd';
const { Title } = Typography;
const Heading = ({children})=>{
    return(
        <Title level={4} style={{
            textAlign: "center",
            background: "#fafafa00",
            color: "black",
            padding: "6px 0px",
            fontSize: "20px",
            fontWeight: "700",
            marginBottom: "0",
            borderBottom: "1px solid #e8e8e8",
        }}> {children}</Title>
    )
}


export default Heading;
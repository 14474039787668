import React, { useEffect, useState } from "react";
import ParkingManagementService from "../../services/ParkingManagementService";
import { Button, Col, Form, Input, Row, Table, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import CommonService from "../../services/CommonService";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";

export default function BuildingView() {

    const [buildings, setBuildings] = useState([])
    const [showCreateBuilding, setShowCreateBuilding] = useState(false)

    useEffect(() => {
        getBuildings()
    }, [])

    function getBuildings() {
        ParkingManagementService.getBuildings().then(response => {
            setBuildings(response.data)
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    function deleteBuilding(id) {
        ParkingManagementService.deleteBuilding(id).then(response => {
            message.info("Delete ok")
            getBuildings()
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total slots',
            dataIndex: 'totalSlots',
            key: 'totalSlots',
        },
        {
            title: 'Reserved slots',
            dataIndex: 'reservedSlots',
            key: 'reservedSlots',
        },
        {
            title: 'Visitor slots',
            dataIndex: 'visitorSlots',
            key: 'visitorSlots',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span onClick={() => deleteBuilding(record.id)}>
                    <a>Delete</a>
                </span>
            ),
        },
    ]

    function onCreateBuilding() {
        setShowCreateBuilding(true)
    }

    function hideCreateBuilding() {
        setShowCreateBuilding(false)
    }

    const AddBuildingForm = Form.create({ name: 'building_form' })(CreateBuildingView);

    return (<div>
       <Heading>Building</Heading>
       <Timer/>
        <Row style={{marginTop:"30px"}}>
            <Col lg={20} offset={2}>
                {
                    showCreateBuilding && <AddBuildingForm onchange={getBuildings} onhide={hideCreateBuilding} />
                }
                {
                    !showCreateBuilding && <Button style={{ marginBottom: "20px" }} type="primary" onClick={onCreateBuilding}>
                        New Building
                        <PlusCircleOutlined />
                    </Button>
                }
                <Table dataSource={buildings} columns={columns} rowKey="id" />
            </Col>
        </Row>
    </div>)

}

function CreateBuildingView(props) {

    const { onchange } = props;

    function handleSubmit(e) {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                ParkingManagementService.createBuilding(values).then(response => {
                    message.info("Created successfully")
                    onchange()
                })

            } else {
                message.info("Missing values!")
            }
        });
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;
    const nameError = isFieldTouched('name') && getFieldError('name');
    const reservedSlotsError = isFieldTouched('reservedSlots') && getFieldError('reservedSlots');
    const visitorSlotsError = isFieldTouched('visitorSlots') && getFieldError('visitorSlots');

    return <div>
        <Form layout="inline" onSubmit={handleSubmit} disabled={hasErrors(getFieldsError())}>
            <Form.Item validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Enter name of building' }],
                })(
                    <Input
                        placeholder="Name"
                    />,
                )}
            </Form.Item>

            <Form.Item validateStatus={reservedSlotsError ? 'error' : ''} help={reservedSlotsError || ''}>
                {getFieldDecorator('reservedSlots', {
                    rules: [{ required: true, message: 'Enter # of Reserved Slots in building' }],
                })(
                    <Input
                        placeholder="Reserved Slots"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={visitorSlotsError ? 'error' : ''} help={visitorSlotsError || ''}>
                {getFieldDecorator('visitorSlots', {
                    rules: [{ required: true, message: 'Enter # of Visitor Slots in building' }],
                })(
                    <Input
                        placeholder="Visitor Slots"
                    />,
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Add</Button>
            </Form.Item>
        </Form>
    </div>
}
class CommonService {

    static Instance() {
        return new CommonService()
    }

    ifExist(array, attr, value) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return true;
            }
        }
        return false;
    }

    findIndex(array, attr, value) {
        if (!array) {
            return -1;
        }
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    getSorted(array, attr, ascending) {
        array.sort((a, b) => this.compare(a, b, attr, ascending));
        return array;
    }

    compare(a, b, attr, ascending) {
        if (a[attr] > b[attr]) {
            if (ascending) {
                return 1;
            } else {
                return -1;
            }
        }
        if (b[attr] > a[attr]) {
            if (ascending) {
                return -1;
            } else {
                return 1;
            }
        }
        return 0;
    }

    handleErrorMessage(error) {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.message) {
                    return error.response.data.message;
                }
                else {
                    return "Something went wrong!";
                }
            }
            else {
                return "Something went wrong!";
            }
        }
        else if (error.Message) {
            return error.Message;
        }
        else if (error.message) {
            return error.message;
        }
        else if (!error.message && !error.response) {
            return "Something went wrong!";
        }
    }

    concatenateStrings(demiltter, ...chunks) {
        let result = "";
        (chunks || []).forEach((chunk, chunkIndex) => {
            if (chunk) {
                result = result + chunk;
            }
            if (chunks.length > chunkIndex + 1 && chunks[chunkIndex + 1]) {
                result = result + demiltter;
            }
        });
        if (result) {
            result = result.trim();
        }
        return result;
    }
    getDistinct(array, attr) {
        let unique = [];
        let distinct = [];
        for (let i = 0; i < array.length; i++) {
            if (!unique[array[i][attr]]) {
                distinct.push(array[i]);
                unique[array[i][attr]] = 1;
            }
        }
        return distinct;
    }

    getDistinctValues(array, attr) {
        let unique = [];
        let distinct = [];
        for (let i = 0; i < array.length; i++) {
            if (!unique[array[i][attr]]) {
                distinct.push(array[i][attr]);
                unique[array[i][attr]] = 1;
            }
        }
        return distinct;
    }
}


export default CommonService.Instance()
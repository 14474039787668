import React, { useEffect, useState } from "react";
import ParkingManagementService from "../../services/ParkingManagementService";
import { Button, Col, Form, Input, Row, Table, message, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import CommonService from "../../services/CommonService";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";
const { Option } = Select;
const { Search } = Input;

export default function VehicleView() {

    const [vehicles, setVehicles] = useState([])
    const [showCreateVehicle, setShowCreateVehicle] = useState(false)
    const [searchFilter, setSearchFilter] = useState({})
    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState()

    useEffect(() => {
        getCompanies()
        getRegisteredVehicles()
    }, [])

    function getCompanies() {
        ParkingManagementService.getCompanies().then(response => {
            setCompanies(response.data)
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    function getRegisteredVehicles(filter) {
        ParkingManagementService.getRegisteredVehicles(filter).then(response => {
            setVehicles(response.data)
            setShowCreateVehicle(false)
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    function deleteVehicle(id) {
        ParkingManagementService.deleteVehicle(id).then(response => {
            message.info("Delete ok")
            getRegisteredVehicles()
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    const columns = [
        {
            title: 'Vehicle',
            dataIndex: 'vehicleNo',
            key: 'vehicleNo',
        },
        {
            title: 'Tag',
            dataIndex: 'tagNo',
            key: 'tagNo',
        },
        {
            title: 'Emp Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: 'Emp ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
        },
        {
            title: 'Company',
            dataIndex: 'companyCode',
            key: 'companyCode',
        },
        {
            title: 'Type',
            dataIndex: 'vehicleType',
            key: 'vehicleType',
        }, {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span onClick={() => deleteVehicle(record.id)}>
                    <a>Delete</a>
                </span>
            ),
        },
    ]

    function onCreateVehicle() {
        setShowCreateVehicle(true)
    }

    function hideCreateVehicle() {
        setShowCreateVehicle(false)
    }

    function searchVehicle(value) {
        searchFilter.term = value
        setSearchFilter(searchFilter)
        getRegisteredVehicles(searchFilter)
    }

    function onSelectCompany(value) {
        searchFilter.company = value
        setSearchFilter(searchFilter)
        setSelectedCompany(value)
        getRegisteredVehicles(searchFilter)
    }

    const AddVehicle = Form.create({ name: 'vehicle_form' })(CreateVehicleView);

    return (<Row>
        <Heading>Vehicles</Heading>
        <Timer/>
        <Col style={{marginTop:"30px"}} lg={20} offset={2}>
            {
                showCreateVehicle && <AddVehicle onchange={getRegisteredVehicles} onhide={hideCreateVehicle} />
            }
            {
                !showCreateVehicle && <Button style={{ marginBottom: "20px" }} type="primary" onClick={onCreateVehicle}>
                    New Vehicle
                    <PlusCircleOutlined />
                </Button>
            }
            <br />
            Company: <Select style={{ width: 200 }} defaultValue="" onChange={onSelectCompany}>
                <Option value="">...</Option>
                {companies.map(company => {
                    return <Option value={company.code}>{company.name}</Option>
                })}
            </Select>
            &nbsp;
            &nbsp;
            &nbsp;

            Vehicle: <Search
                placeholder="input search text"
                onSearch={searchVehicle}
                allowClear
                style={{ width: 200 }}
                enterButton
            />
            <br /><br />
            <Table dataSource={vehicles} columns={columns} rowKey="id" />
        </Col>
    </Row>)

}

function CreateVehicleView(props) {

    const { onchange } = props;
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        getCompanies()
    }, [])

    function getCompanies() {
        ParkingManagementService.getCompanies().then(response => {
            setCompanies(response.data)
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {

                ParkingManagementService.createVehicle(values).then(response => {
                    message.info("Created successfully")
                    onchange()
                })
                    .catch(error => {
                        message.error(CommonService.handleErrorMessage(error));
                    })

            } else {
                message.info("Missing values!")
            }
        });
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;
    const employeeIdError = isFieldTouched('employeeId') && getFieldError('employeeId');
    const employeeNameError = isFieldTouched('employeeName') && getFieldError('employeeName');
    const companyError = isFieldTouched('company') && getFieldError('company');
    const vehicleNoError = isFieldTouched('vehicleNo') && getFieldError('vehicleNo');
    const tagNoError = isFieldTouched('tagNo') && getFieldError('tagNo');
    const typeError = isFieldTouched('type') && getFieldError('type');

    return <div>
        <Form layout="inline" onSubmit={handleSubmit} disabled={hasErrors(getFieldsError())}>
            <Form.Item validateStatus={vehicleNoError ? 'error' : ''} help={vehicleNoError || ''}>
                {getFieldDecorator('vehicleNo', {
                    rules: [{ required: true, message: 'Enter Vehicle Number' }],
                })(
                    <Input
                        placeholder="Vehicle No"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={tagNoError ? 'error' : ''} help={tagNoError || ''}>
                {getFieldDecorator('tagNo', {
                    rules: [{ required: true, message: 'Enter Vehicle Number' }],
                })(
                    <Input
                        placeholder="TAG NO"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={companyError ? 'error' : ''} help={companyError || ''}>
                {getFieldDecorator('company', {
                    rules: [{ required: true, message: 'Enter Company' }],
                })(
                    <Select style={{ width: 120 }} >
                        {companies.map(company => {
                            return <Option value={company.code}>{company.name}</Option>
                        })}

                    </Select>,
                )}
            </Form.Item>
            <Form.Item validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
                {getFieldDecorator('type', {
                    rules: [{ required: true, message: 'Enter Vehicle Type' }],
                })(
                    <Select style={{ width: 120 }} >
                        <Option value="Car">Car</Option>
                        <Option value="Bike">Bike</Option>
                    </Select>,
                )}
            </Form.Item>
            <Form.Item validateStatus={employeeNameError ? 'error' : ''} help={employeeNameError || ''}>
                {getFieldDecorator('employeeName', {
                    rules: [{ required: true, message: 'Enter Employee Name' }],
                })(
                    <Input
                        placeholder="Employee Name"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={employeeIdError ? 'error' : ''} help={employeeIdError || ''}>
                {getFieldDecorator('employeeId', {
                    rules: [{ required: false, message: 'Enter Employee Id' }],
                })(
                    <Input
                        placeholder="Employee Id"
                    />,
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Add</Button>
                <Button type="link" onClick={onchange}>Cancel</Button>
            </Form.Item>
        </Form>
    </div>
}

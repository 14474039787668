import React, { Component } from "react";
import { FeedMapView } from "./FeedMapView";
import { PageHeader } from "antd";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";

export default class FeedDashboard extends Component {


    render() {
        return (
            <div>
               <Heading> Dashboard</Heading>
               <Timer/>
                <PageHeader
                    className="site-page-header"
                // title="Dashboard"
                />
                <FeedMapView />
            </div>
        );
    }

}
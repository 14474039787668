import axios from "./axios";

class ParkingManagementService {

    static Instance() {
        return new ParkingManagementService()
    }

    //building crud
    getBuildings() {
        return axios.post('/api/apms/building/list');
    }

    createBuilding(building) {
        return axios.post('/api/apms/building/', building)
    }

    updateBuilding() {

    }

    deleteBuilding(id) {
        return axios.delete('/api/apms/building/' + id);
    }

    //company crud
    getCompanies() {
        return axios.post('/api/apms/company/list');
    }

    createCompany(company) {
        return axios.post('/api/apms/company/', company);
    }

    deleteCompany(id) {
        return axios.delete('/api/apms/company/' + id);
    }

    //vehicle crud
    getRegisteredVehicles(filter) {
        if (filter) {
            return axios.post('/api/apms/vehicle/filter', filter);
        }
        return axios.post('/api/apms/vehicle/list', filter);
    }

    createVehicle(vehicle) {
        return axios.post('/api/apms/vehicle/', vehicle);
    }

    deleteVehicle(id) {
        return axios.delete('/api/apms/vehicle/' + id);
    }
}

export default ParkingManagementService.Instance()
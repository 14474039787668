import React, {Component} from "react";
import {Button, DatePicker, Icon, Select, TimePicker, message, Typography, Input} from 'antd';
import feedService from "../../services/FeedService";
import CommonService from "../../services/CommonService";
const { Option } = Select;
export default class ApcGenericFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedsList: [{ id: 0, location: "ALL", site: "ALL" }],
        };
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onFromTimeChange = this.onFromTimeChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);
        this.onToTimeChange = this.onToTimeChange.bind(this);
        this.fetchFeedsList = this.fetchFeedsList.bind(this);
        this.feedSelected = this.feedSelected.bind(this);
        this.onEventIdInputChange = this.onEventIdInputChange.bind(this);
    }

    componentDidMount(){
        this.fetchFeedsList();
    }

    onFromDateChange(date) {
        let {filter}  = this.props;
        if (date) {
            filter.fromDate = date.format("YYYY-MM-DD");
        } else {
            filter.fromDate = null;
        }
        this.props.handleFilterChange(filter);
        this.props.handleRefresh();
    }

    onFromTimeChange(time) {
        let {filter}  = this.props;
        if (time) {
            filter.fromTime = time.format("HH:mm:ss");
        } else {
            filter.fromTime = null;
        }
        this.props.handleFilterChange(filter);
        this.props.handleRefresh();
    }

    onToDateChange(date) {
        let {filter}  = this.props;
        if (date) {
            filter.toDate = date.format("YYYY-MM-DD");
        } else {
            filter.toDate = null;
        }
        this.props.handleFilterChange(filter);
        this.props.handleRefresh();
    }

    onToTimeChange(time) {
        let {filter}  = this.props;
        if (time) {
            filter.toTime = time.format("HH:mm:ss");
        } else {
            filter.toTime = null;
        }
        this.props.handleFilterChange(filter);
        this.props.handleRefresh();
    }

    fetchFeedsList() {
        feedService.getFeeds().then(res => {
            this.setState({ feedsList: [{ id: 0, location: "ALL", site: "ALL" }, ...res.data] });
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    feedSelected = feedId => {
        let {filter}  = this.props;
        filter.feedId = feedId;
        this.props.handleFilterChange(filter);
        this.props.handleRefresh();
    }

    onEventIdInputChange(e) {
        let {filter}  = this.props;
        filter.eventId = e.target.value;
        this.props.handleFilterChange(filter);
        this.props.handleRefresh();
    }

    render() {
        let {filter, handleRefresh}  = this.props;
        let {feedsList}  = this.state;
        return (
            <div>
                <span>Event Id:</span>
                 <Input value={filter.eventId} style={{ "width": "200px" }}
                                 onChange={this.onEventIdInputChange} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>Location: </span>
                <Select
                    style={{ width: 200 }}
                    placeholder="Select Location"
                    onChange={this.feedSelected}
                >
                    {(feedsList || []).map(feed => <Option
                        value={feed.id}>{feed.site + " > " + feed.location}</Option>)}
                </Select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>From: </span>
                <DatePicker onChange={this.onFromDateChange}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <TimePicker minuteStep={15} secondStep={60}
                            onChange={this.onFromTimeChange}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>To: </span>
                <DatePicker onChange={this.onToDateChange}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <TimePicker minuteStep={15} secondStep={60}
                            onChange={this.onToTimeChange}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={handleRefresh}><Icon type="reload"/>Reload</Button>

            </div>
        )
    }
}

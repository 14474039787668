import React, {Component} from 'react'
import {Col, message, Row, Switch, Card, Button, Table} from "antd";
import AlertService from "../../services/AlertService";

const {Column} = Table;
export default class AlertConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertTypes: []
        }
        this.fetchAlertTypes = this.fetchAlertTypes.bind(this);
        this.toggleAlert = this.toggleAlert.bind(this);
        this.toggleGenerateAlert = this.toggleGenerateAlert.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
    }

    componentDidMount() {
        this.fetchAlertTypes()
    }

    fetchAlertTypes = async () => {
        try {
            const alertTypesResponse = await AlertService.fetchAlertTypes();
            const alertTypes = alertTypesResponse.data;
            this.setState({alertTypes})
        } catch (err) {
            message.error("Something Went Wrong");
            console.log(err)
        }
    }

    toggleAlert = (enabled, i) => {
        const alertTypes = [...this.state.alertTypes];
        alertTypes[i].enabled = enabled;
        this.setState({alertTypes})
    }

    toggleGenerateAlert = (generateAlert, i) => {
        const alertTypes = [...this.state.alertTypes];
        alertTypes[i].generateAlert = generateAlert;
        this.setState({alertTypes})
    }

    saveConfig = async () => {
        try {
            await AlertService.saveAlertSettings(this.state.alertTypes);
            message.success("Saved");
        } catch (e) {
            console.log(e);
            message.error("Something Went Wrong");
        }
    }

    render() {
        return (
            <div style={{padding: "40px"}}>
                <Card title={"VIDS Settings"}
                      actions={[<Button type={"primary"} onClick={this.saveConfig}>Save</Button>]}
                    >
                    <Table dataSource={this.state.alertTypes}
                           bordered={true}
                           size={"small"}
                           pagination={false}
                    >
                        <Column title="Alert Type" dataIndex="alertType" key="alertType"
                                render={(value, record, index) => value}/>
                        <Column title="Enabled" dataIndex="enabled" key="enabled"
                                render={(value, record, index) => <Switch checked={value}
                                                                          onChange={value => this.toggleAlert(value, index)}/>}/>
                        <Column title="Generate Alert" dataIndex="generateAlert" key="generateAlert"
                                render={(value, record, index) => <Switch checked={value}
                                                                          onChange={value => this.toggleGenerateAlert(value, index)}/>}/>
                    </Table>

                </Card>

            </div>
        )

    }

}
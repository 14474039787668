/*global google*/
import React, {Component} from 'react';
import {message} from "antd";
import {
    withScriptjs, withGoogleMap, GoogleMap
} from "react-google-maps";
import FeedService from "../../services/FeedService";

const {MarkerWithLabel} = require("react-google-maps/lib/components/addons/MarkerWithLabel");


const Map = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={4}
            defaultCenter={{lat: 22.918782, lng: 79.835484}}
        >
            {props.marks.map((mark, index) => (
                <MarkerWithLabel key={index}
                                 position={mark}
                                 labelAnchor={new google.maps.Point(0, 0)}
                                 labelStyle={{backgroundColor: "#f2eccf", fontSize: "18px", padding: "5px"}}

                >
                    <div>{mark.value}</div>
                </MarkerWithLabel>
            ))}
        </GoogleMap>
    ))
);


export class FeedMapView extends Component {


    constructor(props) {
        super(props);

        this.state = {
            showMap: false,
            marks: [],
            mapUrl: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCqJTLej7ail_l37RZuDvpijXyyBuOQdGc"
        };


    }

    componentDidMount() {
        this.fetchFeedsList();

    }

    fetchFeedsList = async () => {
        try {
            const res = await FeedService.getFeeds()
            const feedsList = res.data;
            let marks = [];
            feedsList.forEach(feed => {
                let item = {
                    name: feed.name,
                    value: feed.name,
                    lat: feed.lat,
                    lng: feed.lng,
                    title: feed.location
                }

                marks.push(item);
            })

            this.setState({feedsList, marks})
        } catch (e) {
            console.log(e)
            message.error("Something Went Wrong ")
        }
    }

    setMark = e => {
        this.setState({marks: [...this.state.marks, e.latLng]});
    };


    render() {
        const {marks} = this.state;
        return (
            <div>
                <Map
                    googleMapURL={this.state.mapUrl}
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `392px`}}/>}
                    mapElement={<div style={{height: `100%`, borderRadius: `10px`, border: `1px solid #d9d9d9`}}/>}

                    marks={marks}
                />
            </div>
        );
    }
}

import { Button, Card, Col, message, Row, Switch, PageHeader, Spin, Input } from 'antd'
import AtccService from "../../services/AtccService"
import CommonService from "../../services/CommonService"
import React, { Component } from 'react'
import Timer from '../../components/Timer';

export class AtccSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            atccSetting: {},
            loading: false,
            saving: false,
            formError: {},
        }
        this.refresh = this.refresh.bind(this);
        this.saveAtccSetting = this.saveAtccSetting.bind(this);
    }
    componentDidMount() {
        // this.refresh()
    }
    refresh() {
        this.setState({ loading: true });
        AtccService.getAtccSettings().then(res => {
            this.setState({ atccSetting: res.data, loading: false });
        }).catch(e => {
            this.setState({ loading: false });
            message.error(CommonService.handleErrorMessage(e));
        })
    }
    handleChange(value, name) {
        let { atccSetting } = this.state;
        atccSetting[name] = value;
        this.setState({ atccSetting });
    }
    saveAtccSetting() {
        let { atccSetting, formError } = this.state;
        if (atccSetting.autoRefresh && (!atccSetting.autoRefreshInSeconds || atccSetting.autoRefreshInSeconds === '0')) {
            formError.autoRefreshInSeconds = "Enter auto refresh in seconds";
            this.setState({ formError });
            return;
        }
        this.setState({ saving: true, formError: {} });
        AtccService.saveAtccSettings(atccSetting).then(res => {
            this.setState({ atccSetting: res.data, saving: false });
            message.success("Saved!")
        }).catch(e => {
            this.setState({ saving: false });
            message.error(CommonService.handleErrorMessage(e));
        })
    }
    render() {
        const { atccSetting, loading, formError } = this.state;
        return (
            <div>
                <Timer/>
                <br />
                <br />
                <Row>
                    <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 4 }} sm={{ span: 2 }} xs={{ span: 2 }} />
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <Card title={<div align={"center"}>ATCC Settings</div>} >
                            {loading ? <div align={"center"}><Spin /></div> :
                                <div>
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Auto refresh : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.autoRefresh}
                                                onChange={val => this.handleChange(val, 'autoRefresh')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Auto refresh in seconds : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}>
                                                <Input
                                                    name={'autoRefreshInSeconds'}
                                                    type="number"
                                                    placeholder="Enter auto refresh in seconds"
                                                    value={atccSetting.autoRefreshInSeconds}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                                />
                                                <div className="text-danger">{formError.autoRefreshInSeconds}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    {/* <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Vehicle class visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.vehicleClassVisible}
                                                onChange={val => this.handleChange(val, 'vehicleClassVisible')} /></div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Vehicle image visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.vehicleImageVisible}
                                                onChange={val => this.handleChange(val, 'vehicleImageVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br /> */}
                                    {/* <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Driver image visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.driverImageVisible}
                                                onChange={val => this.handleChange(val, 'driverImageVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> LPR visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.lprVisible}
                                                onChange={val => this.handleChange(val, 'lprVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> LPR image visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.lprImageVisible}
                                                onChange={val => this.handleChange(val, 'lprImageVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br /> */}
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Speed visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.speedVisible}
                                                onChange={val => this.handleChange(val, 'speedVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Lane visible: </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.laneVisible}
                                                onChange={val => this.handleChange(val, 'laneVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Direction visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.directionVisible}
                                                onChange={val => this.handleChange(val, 'directionVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Image visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.imageVisible}
                                                onChange={val => this.handleChange(val, 'imageVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Video visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.videoVisible}
                                                onChange={val => this.handleChange(val, 'videoVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Date & Time visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.dateTimeVisible}
                                                onChange={val => this.handleChange(val, 'dateTimeVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Location visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={atccSetting.locationVisible}
                                                onChange={val => this.handleChange(val, 'locationVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    {/* <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Helmet missing visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.helmetMissingVisible}
                                                onChange={val => this.handleChange(val, 'helmetMissingVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Overspeeding visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.overSpeedingVisible}
                                                onChange={val => this.handleChange(val, 'overSpeedingVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br /> */}
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Screenshot visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.screenshotVisible}
                                                onChange={val => this.handleChange(val, 'screenshotVisible')} />
                                            </div>
                                        </Col>
                                    </Row><br />
                                    {/* <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Vehicle color visible : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch
                                                checked={atccSetting.vehicleColorVisible}
                                                onChange={val => this.handleChange(val, 'vehicleColorVisible')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <div align={"center"}>
                                        <Button type={"primary"} onClick={() => this.saveAtccSetting()}
                                            loading={this.state.saving}>
                                            Save
                                        </Button>
                                    </div> */}
                                </div>}
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
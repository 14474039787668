import React, { useState, useEffect } from 'react'
import { Button, Card, Col, InputNumber, message, Row, Switch } from 'antd'
import AnprService from "../services/AnprService";
import Heading from '../components/Heading';
import Timer from '../components/Timer';

export default function AnprSpeedSettings() {

    const [enabled, setEnabled] = useState(false);
    const [carLimit, setCarLimit] = useState(0);
    const [truckLimit, setTruckLimit] = useState(0);
    const [bikeLimit, setBikeLimit] = useState(0);
    const [tolerance, setTolerance] = useState(0);
    const [defaultLimit, setDefaultLimit] = useState(0);

    useEffect(() => {
        fetchSettings()
    }, [])

    const fetchSettings = () => {
        AnprService.fetchSpeedSettings()
            .then(res=> {
                const {enabled, carLimit, truckLimit, bikeLimit, tolerance, defaultLimit} = res.data
                setEnabled(enabled);
                setCarLimit(carLimit);
                setTruckLimit(truckLimit);
                setBikeLimit(bikeLimit);
                setTolerance(tolerance);
                setDefaultLimit(defaultLimit)
            })
            .catch(e => {
                console.log(e)
                message.error("Something Went Wrong")
            })
    }

    const saveSettings = () => {
        const payload = {enabled, carLimit, truckLimit, bikeLimit, tolerance, defaultLimit}
        AnprService.saveSpeedSettings(payload)
            .then(res => {
                message.success("Settings Saved")
                fetchSettings()
            })
            .catch(e => {
                console.log(e)
                message.error("Something Went Wrong!")
            })
    }

    return (
        <div align={"center"}>
           <Heading>Edit ANPR Speed Settings</Heading>
           <Timer/>
            <Card style={{ margin: 40, padding: 40 }}>
                <Row>
                    <Col>
                        <b>Enable Settings : <Switch checked={enabled} onChange={val => setEnabled(val)} /></b>
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col span={6} offset={9}>
                        <b>Enter Car Limit : </b><InputNumber min={0} value={carLimit} placeholder={"Enter Car Limit"} onChange={num => setCarLimit(num)} />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col span={6} offset={9}>
                        <b>Enter Truck Limit : </b><InputNumber min={0} value={truckLimit} placeholder={"Enter Truck Limit"} onChange={num => setTruckLimit(num)} />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col span={6} offset={9}>
                        <b>Enter Bike Limit : </b><InputNumber min={0} value={bikeLimit} placeholder={"Enter Bike Limit"} onChange={num => setBikeLimit(num)} />
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col span={6} offset={9}>
                        <b>Enter Default Limit : </b><InputNumber min={0} value={defaultLimit} placeholder={"Enter Default Limit"} onChange={num => setDefaultLimit(num)} />
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col span={6} offset={9}>
                        <b>Enter Tolerance : </b><InputNumber min={0} value={tolerance} placeholder={"Enter Tolerance"} onChange={num => setTolerance(num)} />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col>
                        <Button type={"primary"} onClick={saveSettings} >Save</Button>
                    </Col>
                </Row>
            </Card>
        </div>
    )

}
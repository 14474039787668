import React, { Component } from 'react'
import { Card, Row, Col, Button, Input, DatePicker, message, Select, Switch, Table, Form, Divider } from 'antd'
import saveAs from 'file-saver'
import LicenseService from "../services/LicenseService";
import CommonService from "../services/CommonService";
import Heading from '../components/Heading';
import Timer from '../components/Timer';

const { Option } = Select;

let expiryFilters = [
    { text: '1m', value: '1m' },
    { text: '3m', value: '3m' },
    { text: '1y', value: '1y' },
    { text: '5y', value: '5y' },
    { text: '10y', value: '10y' },
    { text: '100y', value: '100y' },
]

function toDays(expiry) {
    switch (expiry) {
        case "1m":
            return 30;
        case "3m":
            return 90;
        case "1y":
            return 365;
        case "5y":
            return 5 * 365;
        case "10y":
            return 10 * 365;
        case "100y":
        case "prepeptual":
            return 100 * 365;
    }
}

export default class SynvisionLicenseView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            macId: "",
            module: "VCAM",
            company: "",
            trial: false,
            expiry: "1y",
            licenses: [],
            filter: {}
        }

        this.setMacId = this.setMacId.bind(this)
        this.setModule = this.setModule.bind(this)
        this.setCompany = this.setCompany.bind(this)
        this.setTrial = this.setTrial.bind(this)
        this.setExpiry = this.setExpiry.bind(this)
        this.setLicenses = this.setLicenses.bind(this)
        this.generateLicense = this.generateLicense.bind(this)
        this.downloadLicense = this.downloadLicense.bind(this)
        this.reissueLicense = this.reissueLicense.bind(this)
        this.generateError = this.generateError.bind(this)
        this.setFilterMacId = this.setFilterMacId.bind(this)
        this.setFilterCompany = this.setFilterCompany.bind(this)
        this.setFilterModule = this.setFilterModule.bind(this)
    }

    componentDidMount() {
        LicenseService.listLicenses().then(res => {
            this.setLicenses(res.data);
        })
    }

    clearFilters() {
        this.setState({ filteredInfo: null });
    };

    generateError(payload) {
        let error = ""
        if (!payload.macId) {
            error = "Enter MAC ID!"
        }

        if (!payload.module) {
            error = "Enter Module name!"
        }

        if (!payload.company) {
            error = "Enter Company"
        }

        if (error) message.error(error)
        return error
    }

    generateLicense() {
        const payload = {
            macId: this.state.macId,
            module: this.state.module,
            company: this.state.company,
            trial: this.state.trial,
            expiry: this.state.expiry
        }
        if (!this.generateError(payload)) {
            LicenseService.generateLicense(payload)
                .then(res => {
                    saveAs(res.data, "license.dat")
                })
                .catch(e => {
                    message.error(CommonService.handleErrorMessage(e));
                })
        }
    }

    downloadLicense(record) {
        LicenseService.downloadLicense(record)
            .then(res => {
                saveAs(res.data, "license.dat")
            })
            .catch(e => {
                message.error(CommonService.handleErrorMessage(e));
            })

    }

    reissueLicense(record) {
        LicenseService.reissueLicense(record)
            .then(res => {
                saveAs(res.data, "license.dat")
            })
            .catch(e => {
                message.error(CommonService.handleErrorMessage(e));
            })

    }

    reissuePermanentLicense(record) {
        LicenseService.reissuePermanentLicense(record)
            .then(res => {
                saveAs(res.data, "license.dat")
            })
            .catch(e => {
                message.error(CommonService.handleErrorMessage(e));
            })

    }
    render() {

        const columns = [
            {
                title: (
                    <div>
                        <Input value={this.state.filter.macId} onChange={this.setFilterMacId} />
                        <div>MacId/HostId</div>
                    </div>
                ),
                dataIndex: 'macId',
                key: 'macId',
            },
            {
                title: (
                    <div>
                        <Input value={this.state.filter.company} onChange={this.setFilterCompany} />
                        <div>Issued To</div>
                    </div>
                ),
                dataIndex: 'company',
                key: 'company',
                sorter: (a, b) => a.company.localeCompare(b.company),
            },
            {
                title: (
                    <div>
                        <Input value={this.state.filter.module} onChange={this.setFilterModule} />
                        <div>Module</div>
                    </div>
                ),
                dataIndex: 'module',
                key: 'module',
                sorter: (a, b) => a.module.localeCompare(b.module),
            },
            {
                title: 'Is Trial?',
                dataIndex: 'trial',
                key: 'trial',
                render: trl => (
                    <span>{trl ? "Yes" : "No"}</span>
                )
            },
            {
                title: 'Expiry',
                dataIndex: 'expiry',
                key: 'expiry',
                filters: expiryFilters,
                sorter: (a, b) => toDays(a.expiry) - toDays(b.expiry),
            },
            {
                title: 'Issue Date',
                dataIndex: 'issueDate',
                key: 'issueDate',
                sorter: (a, b) => a.issueDate.localeCompare(b.issueDate),
            },
            {
                title: 'Expiry Date',
                dataIndex: 'expiryDate',
                key: 'expiryDate',
                sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate),
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.downloadLicense(record)}>Download</a>
                        <Divider type="vertical" />
                        <a onClick={() => this.reissueLicense(record)}>Reissue</a>
                        <Divider type="vertical" />
                        <a onClick={() => this.reissuePermanentLicense(record)}>Permanent</a>
                    </span>
                ),
            },
        ];

        let licenses = this.filterData(this.state.licenses, this.state.filter)

        return (
            <div align={"center"}>
            <Heading>Generate Token</Heading>
            <Timer/>
                <Form>
                    {/* <h1><b>Generate License</b></h1> */}
                    <Card style={{ padding: 10, margin: 10 }}>
                        <Row>
                            <Col span={3}>
                                <b>Module:</b>
                            </Col>
                            <Col span={3}>
                                <Select style={{ width: 120 }} defaultValue={this.state.module}
                                    onChange={e => this.setModule(e)}>
                                    <Option value="VCAM">VCAM</Option>
                                    <Option value="VASD">VASD</Option>
                                    <Option value="ANPR">ANPR</Option>
                                    <Option value="VIDS">VIDS</Option>
                                </Select>
                            </Col>
                            <Col span={3}>
                                <b>Company:</b>
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    <Select style={{ width: 120 }} value={this.state.company} onChange={this.setCompany}
                                        Placeholder="Select company...">
                                        <Option value="synlabs">synlabs</Option>
                                        <Option value="envoys">envoys</Option>
                                        <Option value="traffiksol">traffiksol</Option>
                                        <Option value="parknsecure">parknsecure</Option>
                                        <Option value="parksmart">parksmart</Option>
                                        <Option value="optimum">optimum</Option>
                                        <Option value="other">other</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <b>Mac/HostID:</b>
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    <Input max={40} min={3} value={this.state.macId} onChange={this.setMacId} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <b>Trial?:</b>
                            </Col>
                            <Col span={3}>
                                <Switch defaultChecked={false} onChange={this.setTrial} />
                            </Col>
                            <Col span={3}>
                                <b>Expiry:</b>
                            </Col>
                            <Col span={3}>
                                <Select style={{ width: 120 }} onChange={this.setExpiry}>
                                    <Option value="1m">1m</Option>
                                    <Option value="3m">3m</Option>
                                    <Option value="1y">1y</Option>
                                    <Option value="5y">5y</Option>
                                    <Option value="10y">10y</Option>
                                    <Option value="100y">100y</Option>
                                </Select>
                            </Col>
                            <Col offset={3} span={3}>
                                <Button type={"primary"} onClick={this.generateLicense}>Generate</Button>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row>
                            <Col>
                                <Table
                                    dataSource={licenses}
                                    columns={columns}>
                                    rowKey={record => record.macId}
                                    pagination={this.state.pagination}
                                    loading={this.state.loading}
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </div>
        )
    }

    filterData(data, filter) {
        if (!data || data.length === 0) {
            return []
        }
        return data.filter((r) => {
            if (filter.macId && filter.macId !== r.macId) {
                return false
            }
            if (filter.company && filter.company !== r.company) {
                return false
            }
            if (filter.module && filter.module !== r.module) {
                return false
            }
            return true
        })
    }

    setExpiry(value) {
        this.setState({ expiry: value })
    }

    setTrial(value) {
        this.setState({ trial: value })
    }

    setMacId(e) {
        this.setState({ macId: e.target.value })
    }

    setCompany(value) {
        this.setState({ company: value })
    }

    setModule(value) {
        this.setState({ module: value })
    }

    setLicenses(licenses) {
        this.setState({ licenses: licenses })
    }

    setFilterMacId(e) {
        let filter = this.state.filter;
        filter.macId = e.target.value;
        this.setState({ filter: filter })
    }

    setFilterCompany(e) {
        let filter = this.state.filter;
        filter.company = e.target.value;
        this.setState({ filter: filter })
    }

    setFilterModule(e) {
        let filter = this.state.filter;
        filter.module = e.target.value;
        this.setState({ filter: filter })
    }
}

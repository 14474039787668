import { Button, Card, Col, message, Row, Switch, PageHeader, Spin, Input } from 'antd'
import AnprService from "../../services/AnprService";
import CommonService from "../../services/CommonService";
import Heading from '../../components/Heading';
import { Component } from 'react';
import React from 'react';
import Timer from '../../components/Timer';

export default class AnprSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anprSetting: {},
            loading: false,
            saving: false,
            formError:{},
        }

        this.refresh = this.refresh.bind(this);
        this.saveAnprSetting = this.saveAnprSetting.bind(this);
    }


    componentDidMount() {
        this.refresh()
    }

    refresh() {
        this.setState({loading: true});
        AnprService.getAnprSettings().then(res => {
            this.setState({anprSetting: res.data, loading: false});
        }).catch(e => {
            this.setState({loading: false});
            message.error(CommonService.handleErrorMessage(e));
        })
    }

    handleChange(value, name) {
        let {anprSetting} = this.state;
        anprSetting[name] = value;
        this.setState({anprSetting});
    }


    saveAnprSetting() {
        let {anprSetting,formError} = this.state;

        if(anprSetting.autoRefresh && (!anprSetting.autoRefreshInSeconds || anprSetting.autoRefreshInSeconds === '0')){
            formError.autoRefreshInSeconds="Enter auto refresh in seconds";
            this.setState({formError});
            return;
        }

        this.setState({saving: true,formError:{}});
        AnprService.saveAnprSettings(anprSetting).then(res => {
            this.setState({anprSetting: res.data, saving: false});
            message.success("Saved!")
        }).catch(e => {
            this.setState({saving: false});
            message.error(CommonService.handleErrorMessage(e));
        })
    }


    render() {
        const {anprSetting, loading,formError} = this.state;
        return (
            <div>
                  <Heading>Settings</Heading>
                  <Timer/>
                <br />
                <Row>
                    <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 4 }} sm={{ span: 2 }} xs={{ span: 2 }} />
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <Card title={<div align={"center"}>ANPR Settings</div>} >
                            {loading ? <div align={"center"}><Spin /></div> :
                                <div>
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Auto refresh : </b>
                                        </Col>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <div style={{ float: 'right' }}><Switch checked={anprSetting.autoRefresh}
                                                onChange={val => this.handleChange(val, 'autoRefresh')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }}
                                            xs={{ span: 12 }}>
                                            <b> Auto refresh in seconds : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}>
                                                <Input
                                                    name={'autoRefreshInSeconds'}
                                                    type="number"
                                                    placeholder="Enter auto refresh in seconds"
                                                    value={anprSetting.autoRefreshInSeconds}
                                                    onChange={(e) => this.handleChange(e.target.value,e.target.name)}
                                                />
                                                <div className="text-danger">{formError.autoRefreshInSeconds}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Vehicle class visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.vehicleClassVisible}
                                                onChange={val => this.handleChange(val, 'vehicleClassVisible')}/></div>
                                        </Col>
                                    </Row>

                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Vehicle image visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.vehicleImageVisible}
                                                onChange={val => this.handleChange(val, 'vehicleImageVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Driver image visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.driverImageVisible}
                                                onChange={val => this.handleChange(val, 'driverImageVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> LPR visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch checked={anprSetting.lprVisible}
                                                                                  onChange={val => this.handleChange(val, 'lprVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> LPR image visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch checked={anprSetting.lprImageVisible}
                                                                                  onChange={val => this.handleChange(val, 'lprImageVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>

                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Speed visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch checked={anprSetting.speedVisible}
                                                                                  onChange={val => this.handleChange(val, 'speedVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Direction visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch checked={anprSetting.directionVisible}
                                                                                  onChange={val => this.handleChange(val, 'directionVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Event date visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch checked={anprSetting.eventDateVisible}
                                                                                  onChange={val => this.handleChange(val, 'eventDateVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Location visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch checked={anprSetting.locationVisible}
                                                                                  onChange={val => this.handleChange(val, 'locationVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Helmet missing visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.helmetMissingVisible}
                                                onChange={val => this.handleChange(val, 'helmetMissingVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Overspeeding visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.overSpeedingVisible}
                                                onChange={val => this.handleChange(val, 'overSpeedingVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Speed limit visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.speedLimitVisible}
                                                onChange={val => this.handleChange(val, 'speedLimitVisible')}/>
                                            </div>
                                        </Col>
                                    </Row><Row>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <b> Vehicle color visible : </b>
                                        </Col>
                                        <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 12}}
                                             xs={{span: 12}}>
                                            <div style={{float: 'right'}}><Switch
                                                checked={anprSetting.vehicleColorVisible}
                                                onChange={val => this.handleChange(val, 'vehicleColorVisible')}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <div align={"center"}>
                                        <Button type={"primary"} onClick={() => this.saveAnprSetting()}
                                            loading={this.state.saving}>
                                            Save
                                        </Button>
                                    </div>
                                </div>}
                        </Card>

                    </Col>
                </Row>
            </div>
        )
    }

}
import React, {Component} from "react";
import {Button, DatePicker, Icon, TimePicker, Col, Row} from 'antd';

const ButtonGroup = Button.Group;

export default class GenericFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            layout: "list",
            filter: {
                page: 1,
                pageSize: 24
            }
        };
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onFromTimeChange = this.onFromTimeChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);
        this.onToTimeChange = this.onToTimeChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    refresh() {
        this.props.handleRefresh();
    }

    onPageChange(page, pageSize) {
        let filter = this.state.filter;
        filter.page = page;
        filter.pageSize = pageSize;
        this.refreshNow(filter)
    }

    onPageSizeChange(current, pageSize) {
        let filter = this.state.filter;
        filter.pageSize = pageSize;
        this.refreshNow(filter);
    }

    changeLayout(layout) {
        this.setState({"layout": layout});
        this.props.handleLayoutChange(layout);
    }

    onFromDateChange(date) {
        let filter = this.state.filter;
        if (date != null) {
            filter.fromDate = date.format("YYYY-MM-DD");
        }
        else {
            filter.fromDate = null;
        }
        this.setState({filter: filter});
        this.props.handleFilterChange(filter);
        this.refresh();
    }

    onFromTimeChange(time) {
        let filter = this.state.filter;
        if (time != null) {
            filter.fromTime = time.format("HH:mm:ss");
        }
        else {
            filter.fromTime = null;
        }
        this.setState({filter: filter});
        this.props.handleFilterChange(filter);
        this.refresh();
    }

    onToDateChange(date) {
        let filter = this.state.filter;
        if (date != null) {
            filter.toDate = date.format("YYYY-MM-DD");
        }
        else {
            filter.toDate = null;
        }
        this.setState({filter: filter});
        this.props.handleFilterChange(filter);
        this.refresh();
    }

    onToTimeChange(time) {
        let filter = this.state.filter;
        if (time != null) {
            filter.toTime = time.format("HH:mm:ss");
        }
        else {
            filter.toTime = null;
        }
        this.setState({filter: filter});
        this.props.handleFilterChange(filter);
        this.refresh();
    }

    render() {

        let layout = this.state.layout;

        return (
            <Row>
                <Col  xs={24} sm={24} md={8} lg={8} xl={10}>
                <span>From: </span>
                <DatePicker onChange={this.onFromDateChange} format={'MM/DD/YYYY'}/>&nbsp;&nbsp;
                <TimePicker minuteStep={15} secondStep={60} onChange={this.onFromTimeChange}/>&nbsp;&nbsp;
                </Col>
                <Col style={{display:"flex", alignItems:"center", paddingLeft:"8px"}} xs={24} sm={24} md={8} lg={8} xl={11}> <span>To: </span>
                <DatePicker onChange={this.onToDateChange}/>&nbsp;&nbsp;
                <TimePicker minuteStep={15} secondStep={60} onChange={this.onToTimeChange}/>&nbsp;&nbsp;
                <Button  style={{float: "right"}} onClick={() => {
                    this.refresh()
                }}><Icon type="reload"/>Refresh</Button></Col>
                <Col style={{display:"flex", justifyContent:"flex-end"}} xs={12} sm={12} md={4} lg={4} xl={3}>
                    <ButtonGroup >
                    <Button type={layout === "list" ? "primary" : ""} icon="table"
                            onClick={() => {
                                this.changeLayout("list")
                            }}>Grid</Button>
                    <Button type={layout === "table" ? "primary" : ""} icon="unordered-list"
                            onClick={() => {
                                this.changeLayout("table")
                            }}>List</Button>
                </ButtonGroup></Col>
                
            </Row>
          
        )
    }
}

import axios from "./axios";

class QueueManagementService {
    static Instance() {
        return new QueueManagementService()
    }

    getQueues(filter) {
        return axios.post('/api/queue/mgmt/queue/list', filter);
    }

    getQueueEvents(filter) {
        return axios.post('api/queue/mgmt/event/list', filter);
    }


}

export default QueueManagementService.Instance()
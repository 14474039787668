import React, { Component } from 'react'
import AvcService from "../../services/AvcService"
import { Table, Button, message } from "antd"
import { FileExcelOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import Heading from '../../components/Heading'
import Timer from '../../components/Timer'

const { Column } = Table

export default class SurveyReports extends Component {
    state = {
        surveys: []
    }

    generateReport = id => {
        AvcService.generateReport(id)
            .then(res => {
                saveAs(res.data, "survey-report.xlsx")
                message.success("Report Generated!")
            })
            .catch(e => {
                console.log(e)
                message.error("Something Went Wrong")
            })
    }

    componentDidMount() {
        this.fetchSurveys()
    }

    fetchSurveys = () => {
        AvcService.fetchSurveys()
            .then(res => {
                const surveys = res.data
                this.setState({ surveys })
            })
            .catch(e => {
                console.log(e)
                message.error("Something Went Wrong")
            })
    }

    render() {
        const data = this.state.surveys.map((survey, i) => {
            return { ...survey, key: i + 1, report: i }
        })
        return (
            <div>
                <Heading>Survey Reports</Heading>
                <Timer/>
                <Table dataSource={data}>
                    <Column title={"Name"} dataIndex={"name"} key={"name"} />
                    <Column title={"Start Date"} dataIndex={"startDate"} key={"startDate"} />
                    <Column title={"End Date"} dataIndex={"endDate"} key={"endDate"} />
                    <Column
                        title={"Report"}
                        dataIndex={"report"}
                        key={"report"}
                        render={(text, record, _) => (
                            <Button onClick={() => this.generateReport(record.id)}><FileExcelOutlined /></Button>
                        )}
                    />
                </Table>
            </div>
        )
    }

}
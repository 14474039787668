import React, { Component } from "react";
import {
    Card,
    Col,
    Collapse,
    Empty,
    Icon,
    Pagination,
    Row,
    Table,
    Tag,
    Input, Button, Typography, Slider,
    message, Spin, Select, Drawer, Tooltip
} from 'antd';
import GenericFilter from "../../components/GenericFilter";
import Moment from "react-moment";
import anprService from "../../services/AnprService";
import Magnifier from "react-magnifier";
import moment from "moment";
import { saveAs } from "file-saver";
import feedService from "../../services/FeedService";
import CommonService from "../../services/CommonService";
import { EyeOutlined } from '@ant-design/icons';
import ReportService from "../../services/ReportService";
import Timer from "../../components/Timer";
import Heading from "../../components/Heading";

const { Paragraph, Text } = Typography;

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;

const DescriptionItem = ({ title, content }) => (
    <div>
        <Col span={4}>
            <strong>{title} :</strong>
        </Col>
        <Col span={8}>
            {content}
        </Col>
    </div>
);

export default class AnprView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            feedsList: ["ALL"],
            activePanelKey: ["1"],
            visible: true,
            loading: true,
            layout: "list",
            showItems: 6,
            filter: {
                feedId: 0,
                page: 1,
                pageSize: 24,
                lpr: "",
                vehicleColor: "",
                overspeeding: false,
                location: ""
            },
            workingEvent: {},
            workingEventLoading: false,
            magnifyEvent: {
                magnifyEventId: "",
                zoomFactor: 2,
                minZoomFactor: 1,
                maxZoomFactor: 5
            },
            downloadingExcelReport: false,
            pageSizeOptions: ['12', '24', '48', '100', "250", "500", "1000", "2500", "5000"],
            autoRefresh: true,
            anprSetting: {
                autoRefresh: false,
                vehicleClassVisible: false,
                vehicleImageVisible: false,
                lprVisible: false,
                lprImageVisible: false,
                speedVisible: false,
                driverImageVisible: false,
                directionVisible: false,
                eventDateVisible: false,
                locationVisible: false,
                helmetMissingVisible: false,
                speedLimitVisible: false,
                overSpeedingVisible: false,
                autoRefreshInSeconds: 0,
            },
            selectedEvent: null,
            anprUpdate: true,
        };

        this.refresh = this.refresh.bind(this);
        this.fetchAnprSetting = this.fetchAnprSetting.bind(this);

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onLprInputChange = this.onLprInputChange.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
        this.magnifyEvent = this.magnifyEvent.bind(this);
        this.updateZoomFactor = this.updateZoomFactor.bind(this);
        this.downloadAnprReport = this.downloadAnprReport.bind(this);

        this.preparePrint = this.preparePrint.bind(this);
        this.onCollapse = this.onCollapse.bind(this);
        this.autoRefreshSwitchChange = this.autoRefreshSwitchChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.fetchFeedsList();
        this.fetchAnprSetting();
        this.refresh();
    }

    componentWillUnmount() {
        clearInterval(this.myInstant);
    }

    refresh() {
        this.setState({ loading: true });
        anprService.getEvents(this.state.filter).then(response => {
            this.setState({ "anprresponse": response.data, loading: false })
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
            this.setState({ loading: false });
        })
    }

    fetchAnprSetting() {
        anprService.getAnprSettings().then(res => {
            let anprSetting = res.data;
            this.setState({ anprSetting });
            if (anprSetting.autoRefresh) {
                this.myInstant = setInterval(() => {
                    this.refresh()
                }, anprSetting.autoRefreshInSeconds * 1000);
            }
        }).catch(error => {
            this.setState({ loading: false });
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    fetchFeedsList() {
        feedService.getFeeds().then(res => {
            this.setState({ feedsList: [{ id: 0, location: "ALL", site: "ALL" }, ...res.data] });
        }).catch(error => {
            message.error(CommonService.handleErrorMessage(error));
        })
    }

    archiveEvent(event) {
        anprService.archiveEvent(event).then(request => {
            this.refresh();
        })
    }

    onLprInputChange(e) {

        let filter = this.state.filter;
        filter.lpr = e.target.value;
        this.setState({ filter: filter })
    }

    handleFilterChange(data) {
        this.setState({ filter: data })
    }

    handleLayoutChange(data) {
        this.setState({ layout: data })
    }

    handleOnClick = e => {
        this.setState({
            visible: false,
        });
    };

    handleRefresh() {
        this.refresh();
    }

    onPageChange(page, pageSize) {
        let filter = this.state.filter;
        filter.page = page;
        filter.pageSize = pageSize;
        this.setState({ filter }, () => {
            this.refresh();
        });
    }

    onPageSizeChange(current, pageSize) {
        let filter = this.state.filter;
        filter.pageSize = pageSize;
        this.setState({ filter }, () => {
            this.refresh();
        });
    }

    editEvent(event) {
        this.setState({ workingEvent: event });
    }

    magnifyEvent(event) {
        let magnifyEvent = this.state.magnifyEvent;
        magnifyEvent.magnifyEventId = event.id;

        this.setState({ magnifyEvent });
    }

    updateZoomFactor(zoomFactor) {
        let magnifyEvent = this.state.magnifyEvent;
        magnifyEvent.zoomFactor = zoomFactor;

        this.setState({ magnifyEvent });
    }

    updateEvent(anprText) {

        let { workingEvent, workingEventLoading } = this.state;
        workingEvent.anprText = anprText;
        workingEventLoading = true;
        this.setState({ workingEvent, workingEventLoading });
        anprService.updateEvent(workingEvent).then(res => {
            let { workingEvent, workingEventLoading } = this.state;
            workingEvent.anprText = anprText;
            workingEventLoading = false;
            this.setState({ workingEventLoading });
        }).catch(error => {
            console.error("error in saving");
            let { workingEventLoading } = this.state;
            workingEventLoading = false;
            this.setState({ workingEventLoading });
        })
    }

    downloadAnprReport() {
        this.setState({ downloadingExcelReport: true });
        let filter = this.state.filter;

        let req = {
            fromDateString: filter.from_date ? moment(filter.fromDate).format('YYYY-MM-DD HH:mm:ss') : "",
            toDateString: filter.to_date ? moment(filter.toDate).format('YYYY-MM-DD HH:mm:ss"') : "",
            xAxis: "All Entry-Exit",
            reportType: "CSV",
            feedId: filter.feedId,
            lpr: filter.lpr,
        };

        ReportService.getAnprReport(req).then(response => {
            this.setState({ downloadingExcelReport: false });
            saveAs(response.data, "anpr-events.csv");
        }).catch(error => {
            this.setState({ downloadingExcelReport: false });
            message.error(CommonService.handleErrorMessage(error));
        });
    }

    preparePrint() {
        window.print();
    }


    onCollapse(change) {
        this.setState({ activePanelKey: change })
    }

    feedSelected = feedId => {
        const filter = { ...this.state.filter, feedId };
        this.setState({ filter })
    }

    vehicleColorSelected = vehicleColor => {
        const filter = { ...this.state.filter, vehicleColor };
        this.setState({ filter })
    }
    overSpeedingSelected = overspeeding => {
        const filter = { ...this.state.filter, overspeeding };
        this.setState({ filter })
    }

    autoRefreshSwitchChange() {
        if (this.state.autoRefresh === false) {
            this.setState({ autoRefresh: true });
            this.myInstant = setInterval(() => {
                this.refresh()
            }, 10000);
        }
        else {
            this.setState({ autoRefresh: false });
            clearInterval(this.myInstant);
        }
    }

    downloadVehicleImage(event) {
        anprService.downloadVehicleImage(event.id)
            .then((response) => {
                saveAs(response.data, event.vehicleImage);
            }).catch(error => {
                message.error(CommonService.handleErrorMessage(error));
            })
    }

    downloadLPRImage(event) {
        anprService.downloadLPRImage(event.id)
            .then((response) => {
                saveAs(response.data, event.vehicleImage);
            }).catch(error => {
                message.error(CommonService.handleErrorMessage(error));
            })
    }

    downloadDriverImage(event) {
        anprService.downloadDriverImage(event.id)
            .then((response) => {
                saveAs(response.data, event.vehicleImage);
            }).catch(error => {
                message.error(CommonService.handleErrorMessage(error));
            })
    }

    downloadDriverImage2(event) {
        anprService.downloadDriverImage2(event.id)
            .then((response) => {
                saveAs(response.data, event.vehicleImage);
            }).catch(error => {
                message.error(CommonService.handleErrorMessage(error));
            })
    }

    showDrawer = (event) => {
        this.setState({
            visible: true,
            selectedEvent: event,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            selectedEvent: {}
        });
    };

    handleChange = (value) => {
        console.log(value, "value")
        this.setState({
                showItems: value.key
              })
      };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isUpdate = JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter)
       if (isUpdate) {
            this.refresh();
        }
    }

    render() {

        let { layout, filter, activePanelKey, downloadingExcelReport, anprSetting, anprUpdate } = this.state;

        return (
            <div>
                <div>
                <Heading>Automatic Number Plate Recognition System</Heading>
                    <Timer/>
                    <Collapse className={"no-print"} bordered={false} defaultActiveKey={['1']}
                        activeKey={activePanelKey}
                        onChange={(e) => this.onCollapse(e)}>
                        <Panel header="Filter" key="1">
                            <Row style={{ marginBottom: 10 }}>
                                <Col xl={{ span: 22 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                    <Row>
                                        <Col xl={{ span: 5 }} lg={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                            LPR: <Input value={filter.lpr} style={{ "width": "200px" }}
                                                onChange={this.onLprInputChange} />&nbsp;&nbsp;
                                        </Col>
                                        <Col xl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }}
                                            xs={{ span: 24 }}>
                                            Location: <Select
                                                style={{ width: 200 }}
                                                placeholder="Select Location"
                                                onChange={this.feedSelected}
                                            >
                                                {(this.state.feedsList || []).map(feed => <Option
                                                    value={feed.id}>{feed.site + " > " + feed.location}</Option>)}


                                            </Select>
                                        </Col>
                                        {

                                            anprSetting.vehicleColorVisible
                                                ? <Col xl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }}
                                                    xs={{ span: 24 }}>
                                                    Vehicle Color: <Select
                                                        style={{ width: 200 }}
                                                        placeholder="Select Vehicle Color"
                                                        onChange={this.vehicleColorSelected}
                                                    >
                                                        <Option value={" "}>Select</Option>

                                                        <Option value={"ALL"}>ALL</Option>
                                                        <Option value={"White"}>White</Option>
                                                        <Option value={"Black"}>Black</Option>
                                                        <Option value={"Red"}>Red</Option>
                                                        <Option value={"Blue"}>Blue</Option>
                                                    </Select>
                                                </Col>
                                                : null
                                        }
                                        {
                                            anprSetting.overSpeedingVisible
                                                ? <Col xl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }}
                                                    xs={{ span: 24 }} Row style={{ marginTop: 0 }}>

                                                    OverSpeeding: <Select
                                                        style={{ width: 200 }}
                                                        placeholder="Select OverSpeed"
                                                        onChange={this.overSpeedingSelected}
                                                    >


                                                        <Option value={"ALL"}> All </Option>
                                                        <Option value={"true"}> Yes </Option>
                                                        <Option value={"false"}> No </Option>
                                                    </Select>
                                                </Col>
                                                : null
                                        }
                                    </Row></Col>


                                <Col xl={{ span: 2 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}
                                    xs={{ span: 24 }}>
                                    <span style={{ display: "flex" }}>
                                        <Button style={{ marginRight: 2 }} icon="printer" type="dashed"
                                            onClick={this.preparePrint}>Print
                                        </Button>
                                        <Button type="dashed"
                                            icon={"file-excel"}
                                            loading={downloadingExcelReport}
                                            onClick={() => this.downloadAnprReport()}>
                                        </Button>
                                    </span>
                                </Col>
                            </Row>
                            <GenericFilter handleRefresh={this.refresh} filter={this.state.filter} layout={layout}
                                handleFilterChange={this.handleFilterChange}
                                handleLayoutChange={this.handleLayoutChange}
                            />
                        </Panel>
                    </Collapse>
                    <div>
                        <Spin spinning={this.state.loading}>
                            {layout === "table" ? (this.renderTable()) : (this.renderGrid())}
                        </Spin>
                    </div>
                </div>


            </div>)
    }

    renderGrid() {
        let {anprUpdate } = this.state;

        if (this.state.loading || !this.state.anprresponse || this.state.anprresponse.totalPage === 0) {
            return <Empty description={false} />
        }
        const { anprSetting } = this.state;
        let events = this.state.anprresponse.events;
        const selectedEvents = events.slice(0, this.state.showItems)
        let workingEventLoading = this.state.workingEventLoading;
        let workingEvent = this.state.workingEvent;
        let count = this.state.anprresponse.totalPages * this.state.anprresponse.pageSize;

        let { magnifyEventId, zoomFactor, minZoomFactor, maxZoomFactor } = this.state.magnifyEvent;
        const mid = ((maxZoomFactor - minZoomFactor) / 2).toFixed(5);
        const preColor = zoomFactor >= mid ? '' : 'rgba(0, 0, 0, .45)';
        const nextColor = zoomFactor >= mid ? 'rgba(0, 0, 0, .45)' : '';
        const marks = {
            1: { label: <span><Icon style={{ color: preColor }} type="zoom-out" /></span> },
            2: { label: <span>2</span> },
            3: { label: <span>3</span> },
            4: { label: <span>4</span> },
            5: { label: <span><Icon style={{ color: nextColor }} type="zoom-in" /></span>, }
        };
        return <div style={{ background: '#ECECEC', padding: '1px' }}>

            <Row gutter={[8, 8]} type="flex">
                {
                    selectedEvents.map((event, index) =>
                        <Col xl={{ span: 4 }} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 24 }} key={index}>
                            <Card
                                title={
                                    <div>
                                        {(anprSetting.vehicleClassVisible && event.vehicleClass) ?
                                            <Tag color="#f50">{event.vehicleClass}</Tag> : null}
                                        {(anprSetting.directionVisible && event.direction && event.direction === "rev") ?
                                            <Tag color="#f50">Reverse</Tag> : null}
                                        {(anprSetting.helmetMissingVisible && event.helmet) ?
                                            <Tag color="#f50">Without helmet</Tag> : null}
                                        <div>
                                            {(anprSetting.overSpeedingVisible && (event.overspeeding || event.sectionSpeed)) ?
                                                <Tag color="#f50">OverSpeeding </Tag> : null}
                                        </div>
                                    </div>
                                }
                                extra={anprUpdate ? <div className={"no-print"}>
                                <Tooltip title="archive">
                                    <Button className={"no-print"} type="danger" shape="circle" icon="delete"
                                        size={"small"}
                                        onClick={() => this.archiveEvent(event)} />
                                </Tooltip>
                            </div> : null}
                                 bordered={true}
                                cover={anprSetting.vehicleImageVisible ? <div>{(magnifyEventId === event.id) ?
                                    <Magnifier src={"/public/anpr/vehicle/" + event.id + "/image.jpg"}
                                        zoomFactor={zoomFactor} /> :
                                    <img
                                        style={{
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                        className="hand-cursor"
                                        src={"/public/anpr/vehicle/" + event.id + "/image.jpg"}
                                        onClick={() => this.downloadVehicleImage(event)} />

                                }</div> : null}
                            >
                                <div className={"no-print"}>
                                    {(magnifyEventId === event.id) ?
                                        <Slider
                                            marks={marks}
                                            min={minZoomFactor}
                                            max={maxZoomFactor}
                                            onChange={this.updateZoomFactor}
                                            value={typeof zoomFactor === 'number' ? zoomFactor : 0}
                                        />
                                        :
                                        <div style={{ height: "30px", textAlign: "center" }}>
                                            <Button size="small" type="dashed" icon={"zoom-in"}
                                                onClick={() => this.magnifyEvent(event)}>
                                                Zoom Image
                                            </Button>
                                        </div>
                                    }
                                </div>
                                {anprSetting.lprImageVisible ?
                                    <div title={'Click here to download LPR image'} style={{ textAlign: "center" }}>
                                        <img
                                            className="hand-cursor lpr-img-print"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxWidth: 150
                                            }}
                                            src={"/public/anpr/lpr/" + event.id + "/image.jpg"}
                                            onClick={() => this.downloadLPRImage(event)} />
                                    </div> : <></>
                                }
                                <div style={{ marginTop: "2px", textAlign: "center" }}
                                    onClick={() => this.editEvent(event)}>
                                    {anprSetting.lprVisible ? <Paragraph
                                        style={{ marginBottom: 0 }}
                                        strong
                                        editable={anprUpdate ? { onChange: this.updateEvent }: false}
                                        copyable>{event.anprText}</Paragraph> : null}
                                    <Text
                                        type="secondary">{(workingEventLoading && workingEvent.id === event.id) ? "saving..." : ""}</Text>
                                    {anprSetting.speedVisible ? <Text
                                        type="secondary">{(event.speed) ? "Speed: " + event.speed : ""} {event.speedLimit ? (" | Limit:" + event.speedLimit) : null}</Text> : null}
                                    {anprSetting.vehicleColorVisible
                                        ? <Text type="secondary">{(event.vehicleColor) ? "Color: " + event.vehicleColor : ""}</Text> : null}
                                    {anprSetting.eventDateVisible ? <div>
                                        <Text code><Icon type="schedule" style={{ marginRight: '2px' }} />
                                            <Moment format="ll">{event.eventDate}</Moment>{' '}
                                            <Moment format="LT">{event.eventDate}</Moment>
                                        </Text>
                                    </div> : null}

                                    {anprSetting.locationVisible && event.feed
                                        ? <div style={{ textAlign: "center", marginTop: "2px" }}>
                                            {event.feed.lat && event.feed.lng
                                                ? <a
                                                    href={"http://maps.google.com/?q=" + event.feed.lat + ',' + event.feed.lng}
                                                    target="_blank">
                                                    <Icon type="environment" />
                                                    {CommonService.concatenateStrings(', ', event.feed.location, event.feed.site, event.feed.name)}
                                                </a>
                                                : <Text code>
                                                    <Icon type="environment" />
                                                    {CommonService.concatenateStrings(', ', event.feed.location, event.feed.site, event.feed.name)}
                                                </Text>
                                            }
                                        </div>
                                        : <Text code>
                                            <Icon type="environment" />
                                            {event.source}
                                        </Text>}
                                </div>
                                {
                                    (anprSetting.driverImageVisible) && (event.driverImage || event.driverImage2)
                                        ? <Row style={{ marginTop: 3 }} gutter={[4, 2]} type="flex" justify="start">
                                            <Col span={12}>
                                                {
                                                    event.driverImage ?
                                                        <div style={{ textAlign: "center" }}>
                                                            <img
                                                                className="hand-cursor driver-img-print"
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto'
                                                                }}
                                                                src={"/public/anpr/driver/" + event.id + "/image.jpg"}
                                                                title={'Click here to download driver image'}
                                                                onClick={() => this.downloadDriverImage(event)} />
                                                        </div>
                                                        : null
                                                }
                                            </Col>
                                            <Col span={12}>
                                                {
                                                    event.driverImage2 ?
                                                        <div style={{ textAlign: "center" }}
                                                            title={'Click here to download driver image'}>
                                                            <img
                                                                className="hand-cursor driver-img-print"
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto'
                                                                }}
                                                                src={"/public/anpr/driver/" + event.id + "/image2.jpg"}
                                                                onClick={() => this.downloadDriverImage2(event)} />
                                                        </div>
                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                        : null
                                }
                            </Card>
                        </Col>
                    )
                }
            </Row>
                       <Row style={{margin:"10px"}} >
                             <Col xl={4} lg={4} md={6} sm={12}>
                                 <Select
                                    labelInValue
                                     defaultValue={{ key: 'Select Row' }}
                                      style={{ width: 120 }}
                                    onChange={this.handleChange}
                                  >
                                       <Option value={6}>Row 1</Option>
                                        <Option value={12}>Row 2</Option>
                                        <Option value={18}>Row 3</Option>
                                        <Option value={24}>Row 4</Option>
                                    </Select>
                            </Col>
                            <Col xl={20} lg={20} md={6} sm={12}>
                              <div style={{ textAlign: "right" }}>
                                 <Pagination onChange={this.onPageChange} onShowSizeChange={this.onPageSizeChange} showSizeChanger
                                         showQuickJumper
                                         defaultCurrent={1} total={count} current={this.state.filter.page}
                                         pageSize={this.state.filter.pageSize} pageSizeOptions={this.state.pageSizeOptions} />
            </div>
            </Col>
            </Row>
        </div>
    }

    renderTable() {
        let {anprUpdate} = this.state;
        if (this.state.loading) {
            return <Empty description={false} />
        }
        let events = this.state.anprresponse.events;
        let count = this.state.anprresponse.totalPages * this.state.anprresponse.pageSize;
        const paginationOptions = {
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: this.onPageSizeChange,
            onChange: this.onPageChange,
            total: count,
            pageSizeOptions: this.state.pageSizeOptions
        };

        const pagination = {
            ...paginationOptions,
            total: count,
            current: this.state.filter.page,
            pageSize: this.state.filter.pageSize
        };
        const { anprSetting, selectedEvent } = this.state;
        return (
            <div>

                <Table dataSource={events} pagination={pagination} size="small">
                    {anprSetting.locationVisible
                        ? <Column title="Location" dataIndex="source" key="location"
                            render={(dataIndex, event, index) => {
                                let result = null;
                                if (event.feed) {
                                    result = <div>
                                        <div>{event.feed.location}</div>
                                        <div>{event.feed.site}</div>
                                        <div>{event.feed.name}</div>
                                    </div>
                                } else {
                                    result = <Text>{event.source}</Text>
                                }
                                return result;
                            }
                            } />
                        : null}
                    {anprSetting.eventDateVisible
                        ? <Column title="Date" dataIndex="eventDate" key="eventDate"
                            render={eventDate => (
                                <div>
                                    <div>
                                        <Moment format="L">{eventDate}</Moment>
                                    </div>
                                    <Moment format="LT">{eventDate}</Moment>
                                </div>
                            )} />
                        : null}
                    {anprSetting.lprImageVisible
                        ? <Column title="LPR" dataIndex="id" key="anprimage"
                            render={(dataIndex, row, index) => (
                                <div>
                                    <div>
                                        <a title={"click here to download LPR image"}
                                            href={"/public/anpr/lpr/" + dataIndex + "/image.jpg"}
                                            download={true}>
                                            <img
                                                className={"lpr-img-print"}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxWidth: 150
                                                }}
                                                src={"/public/anpr/lpr/" + dataIndex + "/image.jpg"} />
                                        </a>
                                        <div>
                                        <Paragraph
                                         strong
                                         copyable>{row.anprText}</Paragraph> 
                                        </div>
                                    </div>
                                </div>)} />
                        : null}
                    {anprSetting.driverImageVisible
                        ? <Column title="Driver Image 1" dataIndex="id" key="driverImage"
                            render={driverImage => (
                                <a title={"click here to download driver image"}
                                    href={"/public/anpr/driver/" + driverImage + "/image.jpg"}
                                    download={true}>
                                    <img
                                        style={{ maxWidth: 70 }}
                                        src={"/public/anpr/driver/" + driverImage + "/image.jpg"} /></a>)} />
                        : null}
                    {anprSetting.driverImageVisible
                        ? <Column title="Driver Image 2" dataIndex="id" key="driverImage2"
                            render={driverImage2 => (
                                <a title={"click here to download driver image"}
                                    href={"/public/anpr/driver/" + driverImage2 + "/image2.jpg"}
                                    download={true}>
                                    <img
                                        style={{ maxWidth: 70 }}
                                        src={"/public/anpr/driver/" + driverImage2 + "/image2.jpg"} /></a>)} />
                        : null}
                    {anprSetting.directionVisible
                        ? <Column title="Direction" dataIndex="direction" key="direction"
                            render={direction => direction} />
                        : null}
                    {anprSetting.helmetMissingVisible
                        ? <Column title="Helmet?" dataIndex="helmet" key="helmet"
                            render={helmet => helmet ? <span>No</span> : <span>N/A</span>} />
                        : null}
                    {anprSetting.speedVisible
                        ? <Column title="Speed" dataIndex="speed" key="speed"
                            render={speed => <span>{speed}</span>} />
                        : null}
                    {anprSetting.vehicleColorVisible
                        ? <Column title="Color" dataIndex="vehicleColor" key="vehicleColor"
                            render={vehicleColor => <span>{vehicleColor}</span>} />
                        : null}
                    {anprSetting.overSpeedingVisible
                        ? <Column title="Overspeeding" dataIndex="Overspeeding" key="Overspeeding"
                            render={Overspeeding => <span>{Overspeeding}</span>} />
                        : null}
                    <Column title="Action"
                        key="action"
                        render={(text, event) => (
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} className={"no-print"}>
                                <div>
                                    <Tooltip title="view">
                                        <Button type="primary" shape="circle" size={"small"}
                                            onClick={() => this.showDrawer(event)}>
                                            <EyeOutlined />
                                        </Button>

                                    </Tooltip>
                                </div>
                                {anprUpdate ? <div style={{ marginTop: '2px' }} className={"no-print"}>
                                    <Tooltip title="archive">
                                        <Button type="danger" shape="circle" icon="delete" size={"small"}
                                            onClick={() => this.archiveEvent(event)} />
                                    </Tooltip>
                                </div> : null}
                            </div>
                        )}
                    />

                </Table>

                {selectedEvent ?
                    <Drawer
                        title={'Vehicle No: ' + selectedEvent.anprText}
                        width={720}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        bodyStyle={{ paddingBottom: 80 }}
                    >
                        <div>

                            {(anprSetting.locationVisible || anprSetting.vehicleClassVisible) ?
                                <Row>
                                    {anprSetting.locationVisible ?
                                        <DescriptionItem title="Location" content={selectedEvent.location} /> : null}
                                    {anprSetting.vehicleClassVisible ?
                                        <DescriptionItem title="Vehicle class" content={selectedEvent.vehicleClass} />
                                        : null}

                                </Row> : null}

                            {anprSetting.eventDateVisible ? <Row style={{ marginTop: '20px' }}>
                                <Col span={4}>
                                    <strong>Event date :</strong>
                                </Col>
                                <Col span={8}>
                                    <Moment format="L">{selectedEvent.eventDate}</Moment>
                                </Col>
                                <Col span={4}>
                                    <strong>Event time :</strong>
                                </Col>
                                <Col span={8}>
                                    <Moment format="LT">{selectedEvent.eventDate}</Moment>
                                </Col>
                            </Row> : null}


                            {(anprSetting.speedVisible || anprSetting.directionVisible) ?
                                <Row style={{ marginTop: '20px' }}>
                                    {anprSetting.speedVisible ?
                                        <DescriptionItem title="Speed" content={selectedEvent.speed} /> : null}
                                    {anprSetting.directionVisible ?
                                        <DescriptionItem title="Direction" content={selectedEvent.direction} /> : null}
                                </Row> : null}


                            {(anprSetting.lprVisible || anprSetting.helmetMissingVisible) ?
                                <Row style={{ marginTop: '20px' }}>
                                    {anprSetting.lprVisible ? <Col span={4}>
                                        <strong>LPR :</strong>
                                    </Col> : null}
                                    {anprSetting.lprVisible ? <Col span={8}>
                                        <div style={{ float: 'left' }} title={'Click here to download LPR image'}>
                                            <img
                                                className="hand-cursor lpr-img-print"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto'
                                                }}
                                                src={"/public/anpr/lpr/" + selectedEvent.id + "/image.jpg"}

                                                onClick={() => this.downloadLPRImage(selectedEvent)} />
                                        </div>
                                    </Col> : <p style={{ textAlign: "center" }}>Image Not Found</p>}

                                    {anprSetting.helmetMissingVisible ? <Col span={4}>
                                        <strong>Helmet :</strong>
                                    </Col> : null}
                                    {anprSetting.helmetMissingVisible ? <Col span={8}>
                                        {selectedEvent.helmet ? <span>No</span> :
                                            <span>N/A</span>}
                                    </Col> : null}
                                </Row> : null}


                            {(anprSetting.vehicleImageVisible || anprSetting.driverImageVisible) ?
                                <Row style={{ marginTop: '20px' }}>
                                    {anprSetting.vehicleImageVisible ? <Col span={8}>
                                        <div style={{ float: 'left' }}>
                                            <img
                                                className="hand-cursor"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto'
                                                }}
                                                src={"/public/anpr/vehicle/" + selectedEvent.id + "/image.jpg"}
                                                title={'Click here to download vehicle image'}
                                                onClick={() => this.downloadVehicleImage(selectedEvent)} />
                                        </div>
                                    </Col> : <p style={{ textAlign: "center" }}>Image Not Found</p>}
                                    <Col span={1} />
                                    {anprSetting.driverImageVisible && selectedEvent.driverImage
                                        ? <Col span={7}>
                                            <div style={{ float: 'left' }}>
                                                <img
                                                    className="hand-cursor"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto'
                                                    }}
                                                    src={"/public/anpr/driver/" + selectedEvent.id + "/image.jpg"}
                                                    title={'Click here to download driver image'}
                                                    onClick={() => this.downloadDriverImage(selectedEvent)} />
                                            </div>
                                        </Col>
                                        : <p style={{ textAlign: "center" }}>Image Not Found</p>
                                    }
                                    <Col span={1} />
                                    {anprSetting.driverImageVisible && selectedEvent.driverImage2
                                        ? <Col span={7}>
                                            <div style={{ float: 'left' }}>
                                                <img
                                                    className="hand-cursor"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto'
                                                    }}
                                                    src={"/public/anpr/driver/" + selectedEvent.id + "/image2.jpg"}
                                                    title={'Click here to download driver image'}
                                                    onClick={() => this.downloadDriverImage(selectedEvent)} />
                                            </div>
                                        </Col>
                                        : <p style={{ textAlign: "center" }}>Image Not Found</p>
                                    }
                                </Row> : null}
                        </div>
                    </Drawer> : null}
            </div>
        )
    }
}
import React, { Component } from "react";
import { Button, Card, Col, Divider, Form, Input, message, Modal, Row, Select, Table, Tag, Typography } from "antd";
import FeedService from "../../services/FeedService";
import { EventBus } from "../../components/event";
import ButtonGroup from "antd/es/button/button-group";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import FormOutlined from "@ant-design/icons/lib/icons/FormOutlined";
import PlaySquareOutlined from "@ant-design/icons/lib/icons/PlaySquareOutlined";
import EnvironmentOutlined from "@ant-design/icons/lib/icons/EnvironmentOutlined";
import { Link } from "react-router-dom";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";


const { Text } = Typography;
const { Column } = Table;
const { confirm } = Modal;
let flag = false;// true to enable editing & false for adding new feed
export default class FeedView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            videoVisible: false,
            formVisible: false,
            layout: "list", //list || grid
            mode: "Add", //Add || Edit
            feed: { url: "", site: "", location: "", name: "", id: "", direction: "" },
            feeds: []
        };

        EventBus.subscribe('feed-refresh', (event) => this.refresh())
        this.addFeeds = this.addFeeds.bind(this);
        this.close = this.close.bind(this);
        this.showFeed = this.showFeed.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        FeedService.getFeeds().then(request => {
            this.setState({ "feeds": request.data, loading: false })
        })
    }

    refreshFeed() {
        this.refresh();
    }

    showDeleteConfirm(feedId, refresh) {
        confirm({
            title: 'Are you sure you want to Delete this Feed',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                FeedService.removeFeed(feedId)
                    .then(() => {
                        refresh();
                        message.success("Deleted Successfully!")
                    }).catch(error => {
                        let msg = "Something went wrong!";
                        if (error && error.response && error.response.data && error.response.data.message) {
                            msg = error.response.data.message;
                            message.warn(msg);
                        }

                    });
            },
            onCancel() {
                console.log('Cancel');

            },
        });
    }

    // startFeed(feed) {
    //     FeedService.startFeed(feed).then(() => {
    //         //this should force re-render :)
    //         this.setState({"refresh_state": Math.random()})
    //     })
    // }

    // stopFeed(feed) {
    //     FeedService.stopFeed(feed).then(() => {
    //         //this should force re-render :)
    //         this.setState({"refresh_state": Math.random()})
    //     })
    // }


    changeLayout(layout) {
        this.setState({ "layout": layout });
    }

    addFeeds() {
        this.setState({
            formVisible: true,
            mode: "Add",
            feed: { url: "", site: "", location: "", name: "", direction: "" },
        })
        flag = false;
    }

    close() {
        this.setState({ "formVisible": false })
    }


    showFeed(FeedId) {
        this.setState({ "formVisible": true, "mode": "Edit" })
        flag = true;
        FeedService.getFeed(FeedId)
            .then(response => {
                this.setState({ feed: response.data })
            },
                error => {
                    message.error(error.response.data.message);
                });
    }

    render() {
        let layout = this.state.layout;
        let feeds = this.state.feeds;

        if (this.state.loading || !this.state.feeds || this.state.feeds.length === 0) {
            feeds = []
        }

        const WrappedFeedForm = Form.create({ name: 'feed_form' })(FeedForm);

        return (
            <div>
               <Row gutter={10}>
                   <Heading>Feed List</Heading>
                   <Timer/>
                    <br />
                    <span>&nbsp;&nbsp;</span>
                    <Button type="primary" onClick={this.addFeeds}>
                        + New Feed
                    </Button>&nbsp;&nbsp;&nbsp;
                    <ButtonGroup>
                        <Button type={layout === "list" ? "primary" : ""} icon="unordered-list"
                            onClick={() => {
                                this.changeLayout("list")
                            }}>List </Button>
                        <Button type={layout === "grid" ? "primary" : ""} icon="table"
                            onClick={() => {
                                this.changeLayout("grid")
                            }}>Grid</Button>
                    </ButtonGroup>
                    <br />
                    <br />
                    {layout === "list"
                        ?
                        (this.state.feeds && this.state.feeds.length > 0)
                            ?
                            <Col span={15}>
                                <Card>
                                    <Table dataSource={this.state.feeds} pagination={false}>
                                        <Column title="Feed URL" dataIndex="url" key="url" />
                                        <Column title="Location" dataIndex="location" key="location" />
                                        <Column title="Name" dataIndex="name" key="name" />
                                        <Column title="Site" dataIndex="site" key="site" />
                                        <Column title="Direction" dataIndex="direction" key="direction" />
                                        <Column title="Action" render={(text, record) => (
                                            <span>
                                                <FormOutlined onClick={this.showFeed.bind(this, record.id)} />
                                                <Divider type="vertical" />
                                                <DeleteOutlined style={{ color: "#ff0000" }}
                                                    onClick={this.showDeleteConfirm.bind(this, record.id, this.refresh)} />
                                                <Divider type="vertical" />
                                                <Link to={{
                                                    pathname: '/feedStream',
                                                    feed: record
                                                }
                                                }>
                                                    <PlaySquareOutlined /></Link>
                                            </span>
                                        )} />
                                    </Table>

                                </Card>
                            </Col>
                            : null
                        :
                        <Row gutter={16}>
                            {
                                feeds.map((feed, index) =>

                                    <Col span={8} key={index}>

                                        <Card
                                            title={
                                                <Tag color="#f50">{feed.site}/{feed.location}/{feed.name}</Tag>
                                            }
                                            cover={<img alt="feedview" src={"/api/feed/" + feed.ID + "/view"} />}
                                            bordered={true}
                                            actions={[
                                                <Link to={{
                                                    pathname: '/feedStream',
                                                    feed: feed
                                                }}>
                                                    <Button>Play</Button></Link>,
                                                <Button onClick={() => {
                                                    this.showDeleteConfirm(feed.id, this.refresh)
                                                }}>Delete</Button>
                                            ]}
                                        >

                                        </Card>
                                        <br />
                                    </Col>
                                )
                            }
                        </Row>}


                    {this.state.formVisible ?
                        <Col span={9}>
                            {this.state.mode === "Add" ?
                                <Card title="Add Feed">
                                    <WrappedFeedForm feed={this.state.feed} close={this.close} />
                                </Card>
                                :
                                <Card title="Edit Feed">
                                    <WrappedFeedForm feed={this.state.feed} refresh={this.refreshFeed}
                                        close={this.close} />

                                </Card>
                            }
                        </Col>
                        : null
                    }
                </Row>
            </div>


        )
    }
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class FeedForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            feed: { url: "", site: "", location: "", name: "", lat: 0, lng: 0, direction: "" }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.close = this.close.bind(this);
    }

    close() {
        this.props.close();
    }


    handleSubmit(e) {

        e.preventDefault();

        const form = this.props.form;
        let feed = {};
        feed.url = form.getFieldValue("url");
        feed.site = form.getFieldValue("site");
        feed.location = form.getFieldValue("location");
        feed.name = form.getFieldValue("name");
        feed.lat = form.getFieldValue("lat");
        feed.lng = form.getFieldValue("lng");
        feed.direction = form.getFieldValue("direction");
        feed.gateType = form.getFieldValue("gateType");
        feed.id = this.props.feed.id;
        let validationError;

        if (!feed.lat || !feed.lng) {
            validationError = "Missing Lat lng"
        }

        if (!feed.site) {
            validationError = "Missing site"
        }

        if (!feed.location) {
            validationError = "Missing location"
        }

        if (!feed.name) {
            validationError = "Missing name"
        }

        if (validationError) {
            this.setState({ validationError: validationError });
            return
        }

        console.log('saving feed', feed);
        this.setState({ submitted: true, loading: true });

        FeedService.addFeed(feed, flag).then(response => {
            if (flag) {
                message.success("Feed updated")
                this.close()
            }
            else {
                message.success("Feed Added")
            }
            EventBus.publish('feed-refresh', {})

        }).catch(error => {
            let msg = "Something went wrong!";
            if (error && error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message;
                message.warn(msg);
            }

        });
    }

    render() {

        const { getFieldDecorator, getFieldsError } = this.props.form;
        const validationError = this.state.validationError;
        return (
            <div>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label="Feed" className="formitem">
                        {getFieldDecorator('url', {
                            initialValue: this.props.feed.url,
                            rules: [{ required: true }],
                        })(
                            <Input
                                type="text"
                                placeholder="rtsp://"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="Site" className="formitem">
                        {getFieldDecorator('site', {
                            initialValue: this.props.feed.site,
                            rules: [{ required: true, message: 'Enter site!' }],
                        })(
                            <Input type="text"
                                placeholder="Site (e.g. gurgaon)" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Location" className="formitem">
                        {getFieldDecorator('location', {
                            initialValue: this.props.feed.location,
                            rules: [{
                                required: true,
                                message: 'Enter location!'
                            }],
                        })(
                            <Input type="text" placeholder="Location (e.g. 9th floor)" />,
                        )}
                    </Form.Item>
                    <div style={{ textAlign: "right" }}><a
                        href={"http://maps.google.com/?q=" + this.props.form.getFieldValue("location")}
                        target="_blank"><EnvironmentOutlined />Go to map </a></div>
                    <Form.Item label="Name" className="formitem">
                        {getFieldDecorator('name', {
                            initialValue: this.props.feed.name,
                            rules: [{ required: true, message: 'Enter name!' }],
                        })(
                            <Input type="text" placeholder="Name (e.g. front gate )" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Direction" className="formitem">
                        {getFieldDecorator('direction', {
                            initialValue: this.props.feed.direction,
                            rules: [{ required: true, message: 'Enter direction!' }],
                        })(
                            <Input type="text" placeholder="Direction (e.g. rev/fwd/IN/OUT )" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Latitude" className="formitem">
                        {getFieldDecorator('lat', {
                            initialValue: this.props.feed.lat,
                            rules: [{ required: true, message: 'Enter latitude!' }],
                        })(
                            <Input type='number' placeholder="Latitude" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Longitude" className="formitem">
                        {getFieldDecorator('lng', {
                            initialValue: this.props.feed.lng,
                            rules: [{ required: true, message: 'Enter longitude!' }],
                        })(
                            <Input type='number' placeholder="Longitude" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Gate Type" className="formitem">
                        {getFieldDecorator('gateType', {
                            initialValue: this.props.feed.gateType,
                            rules: [{ required: true, message: 'Gate Type!' }],
                        })(
                            <Select >
                                <Select.Option value=""></Select.Option>
                                <Select.Option value="NA">NA</Select.Option>
                                <Select.Option value="Entry">Entry</Select.Option>
                                <Select.Option value="Exit">Exit</Select.Option>
                            </Select>
                        )}

                    </Form.Item>

                    {validationError && <Text type="danger">{validationError}</Text>}
                    <br />
                    <Button htmlType="submit" type="primary" disabled={hasErrors(getFieldsError())}>Save</Button>

                    <span>&nbsp;&nbsp;</span>
                    <Button type="secondary" onClick={this.close}>
                        Close
                    </Button>

                </Form>
            </div>
        )
    }
}
import React, {Component} from "react";
import {Collapse, Empty, message, Table, Typography} from 'antd';

import ApcGenericFilter from "./ApcGenericFilter";
import Moment from "react-moment";
import ApcFileService from "../../services/ApcFileService";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";
import CommonService from "../../services/CommonService";
import {EventBus} from "../../components/event";

const { Paragraph, Text } = Typography;
const { Column } = Table;
const { Panel } = Collapse;

export default class PeopleCounting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            events: {},
            filter: {
                feedId: 0,
                page: 1,
                pageSize: 24
            },
            pageSizeOptions: [12, 24, 48, 96]
        };

        this.refresh = this.refresh.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);


    }

    componentDidMount() {
        this.refresh();
        EventBus.subscribe("apc-event", ()=> {this.refresh()})
    }

    onPageSizeChange(current, pageSize) {
        let filter = this.state.filter;
        filter.page = current;// Here i made change
        filter.pageSize = pageSize;
        this.refresh(filter);
    }

    refresh(filter) {
        if (!filter) {
            filter = this.state.filter;
        }
        this.setState({ loading: true });
        ApcFileService.getPeopleData(filter).then(response => {
            let data = response.data;
            filter.pageSize = data.pageSize;
            filter.currentPage = data.pageNumber;
            filter.totalPages = data.totalPages;
            this.setState({ filter, loading: false, events: data.list });
        }).catch(error => {
            message.error("Error, something went wrong!!")
        })
    }

    handleFilterChange(filter) {
        filter.page = 1;
        this.setState({ filter })
    }

    handleRefresh() {
        this.refresh(this.state.refresh);
    }

    onPageChange(page, pageSize) {
        let filter = this.state.filter;
        filter.page = page;
        filter.pageSize = pageSize;
        this.refresh(filter)
    }

    archiveEvent(event) {
        ApcFileService.archiveEvent(event).then(() => {
            this.refresh();
        })
    }

    render() {
        return (
            <div>
               <Heading>Intrusion and Fall Events</Heading>
               <Timer/>
                <Collapse bordered={true} defaultActiveKey={['1']}>
                    <Panel header="Filter" key="1">
                        <ApcGenericFilter handleRefresh={this.refresh} filter={this.state.filter}
                            handleFilterChange={this.handleFilterChange}
                        />
                    </Panel>
                </Collapse>
                <div>
                    {this.renderTable()}
                </div>
            </div>
        );
    }

    renderTable() {

        if (this.state.loading) {
            return <Empty description={false} />
        }
        let events = this.state.events;
        let count = this.state.filter.totalPages * this.state.filter.pageSize;
        const paginationOptions = {
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: this.onPageSizeChange,
            onChange: this.onPageChange,
            total: count,
            pageSizeOptions: this.state.pageSizeOptions
        };
        const pagination = {
            ...paginationOptions,
            total: count,
            current: this.state.filter.currentPage,
            pageSize: this.state.filter.pageSize
        };
        return (<div>
            <Table dataSource={events} pagination={pagination}>
                <Column title="ID" dataIndex="eventId" key="eventId"
                    render={eventId => <Paragraph strong copyable>{eventId}</Paragraph>} />
                <Column title="Image" dataIndex="id" key="id"
                    render={id => <img src={"/public/apc/people/" + id + "/image.jpg"}></img>} />                   
                <Column title="Date" dataIndex="eventDate" key="eventDate"
                    render={eventDate => (<Moment format="lll">{eventDate}</Moment>)} />
                <Column title="Location" dataIndex="source" key="location"
                        render={(dataIndex, event, index) => {
                            let result = null;
                            if (event.feed) {
                                result = <div>
                                    {CommonService.concatenateStrings(", ",event.feed.name, event.feed.location, event.feed.site)}
                                </div>
                            } else {
                                result = <Text>{event.source}</Text>
                            }
                            return result;
                        }
                        } />
                <Column title="Direction" dataIndex="direction" key="direction"
                    render={direction => direction} />
                <Column title="Incident Type" dataIndex="incidentType" key="incidentType"
                    render={incidentType => incidentType} />
            </Table>
        </div>
        );

    }
}
import React, {Component} from "react";
import {
    Col,
    Row,
    DatePicker,
    Button,
    Icon,
    Card,
    Modal,
    Menu,
    Dropdown,
    Table, Tag
} from "antd";
import DashboardService from "../../services/DashboardService";
import {Line, Bar, HorizontalBar} from 'react-chartjs-2';
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import Timer from "../../components/Timer";

const {RangePicker} = DatePicker;
const {Column} = Table;

export default class ApcDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datasets: {
                chartData: {},
                options: {},
            }, datasets2: {
                chartData: {},
                options: {},
            }
        };
    }

    componentDidMount() {
        this.refresh();
    }


    refresh() {
        this.getDataset();
        this.getDataset2();
    }

    getBarChartOptions2() {
        let yAxisScaleLabel = "Date";
        let yAxisLabel = "Visits";

        let options = {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            legend: {
                position: 'right'
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true
                    }, scaleLabel: {
                        display: true,
                        labelString: yAxisScaleLabel
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true
                    }, scaleLabel: {
                        display: true,
                        labelString: yAxisLabel
                    }
                }]
            },
            elements: {
                line: {
                    fill: false,
                }
            },
            plugins: {
                datalabels: {
                    display: false,
                    anchor: 'end',
                    clamp: true,
                    align: 'end',
                    offset: 6,
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    color: 'white',
                    font: {
                        weight: 'bold'
                    }
                }
            }


        };
        return options;
    }

    getBarChartOptions() {
        let yAxisScaleLabel = "Date";
        let yAxisLabel = "Visit Time (In minutes)";

        let options = {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            legend: {
                position: 'right'
            },
            scales: {
                xAxes: [{
                    stacked: false,
                    ticks: {
                        beginAtZero: true
                    }, scaleLabel: {
                        display: true,
                        labelString: yAxisScaleLabel
                    }
                }],
                yAxes: [{
                    stacked: false,
                    ticks: {
                        beginAtZero: true
                    }, scaleLabel: {
                        display: true,
                        labelString: yAxisLabel
                    }
                }]
            },
            elements: {
                line: {
                    fill: false,
                }
            },
            plugins: {
                datalabels: {
                    display: false,
                    anchor: 'end',
                    clamp: true,
                    align: 'end',
                    offset: 6,
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    color: 'white',
                    font: {
                        weight: 'bold'
                    }
                }
            }


        };
        return options;
    }

    getDataset() {
        let {datasets} = this.state;

        datasets.chartData.labels = ["10 Apr", "11 Apr", "12 Apr", "13 Apr", "14 Apr", "15 Apr"];

        //prepare dataset
        let dataset1 = {
            label: "Queue 1",
            data: [12, 15, 10, 13, 5, 9],
            borderColor: DashboardService.getColor(0),
            backgroundColor: DashboardService.getColor(0)
        };
        let dataset2 = {
            label: "Queue 2",
            data: [8, 4, 12, 10, 7, 11],
            borderColor: DashboardService.getColor(2),
            backgroundColor: DashboardService.getColor(2)
        };
        let dataset3 = {
            label: "Queue 3",
            data: [9, 8, 10, 12, 9, 13],
            borderColor: DashboardService.getColor(3),
            backgroundColor: DashboardService.getColor(3)
        };
        datasets.chartData.datasets = [dataset1, dataset2, dataset3];
        datasets.options = this.getBarChartOptions();
        this.setState({datasets});
    }

    getDataset2() {
        let {datasets2} = this.state;

        datasets2.chartData.labels = ["10 Apr", "11 Apr", "12 Apr", "13 Apr", "14 Apr", "15 Apr"];

        //prepare dataset
        let dataset1 = {
            label: "Queue 1",
            data: [55, 38, 51, 40, 58],
            borderColor: DashboardService.getColor(0),
            backgroundColor: DashboardService.getColor(0)
        };
        let dataset2 = {
            label: "Queue 2",
            data: [35, 45, 48, 40, 52],
            borderColor: DashboardService.getColor(2),
            backgroundColor: DashboardService.getColor(2)
        };
        let dataset3 = {
            label: "Queue 3",
            data: [40, 48, 56, 40, 55],
            borderColor: DashboardService.getColor(3),
            backgroundColor: DashboardService.getColor(3)
        };
        datasets2.chartData.datasets = [dataset1, dataset2, dataset3];
        datasets2.options = this.getBarChartOptions2();
        this.setState({datasets2});
    }

    render() {
        let {datasets, datasets2} = this.state;

        return (
            <div style={{"marginLeft": 10}}>
                <Timer/>
                <Breadcrumb style={{"marginLeft": 30, "marginTop": 10, "marginBottom": 10}}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Queue Management</Breadcrumb.Item>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
                <Row gutter={16}>
                    <Col xl={{span: 5}} lg={{span: 5}} md={{span: 6}} sm={{span: 8}} xs={{span: 12}}
                         className="gutter-row">
                        <Card title={"Queues"}>
                            <h2>3</h2>
                            <paragraph><Tag color="green"><Icon type="check-circle"/></Tag>All operational</paragraph>
                        </Card>

                    </Col>
                    <Col xl={{span: 5}} lg={{span: 5}} md={{span: 6}} sm={{span: 8}} xs={{span: 12}}
                         className="gutter-row">
                        <Card title={"Total Visits Today"}>
                            <h2>52</h2>
                            <paragraph><Tag color="green"><Icon type="arrow-up"/>3</Tag> more than yesterday</paragraph>
                        </Card>

                    </Col>
                    <Col xl={{span: 5}} lg={{span: 5}} md={{span: 6}} sm={{span: 8}} xs={{span: 12}}
                         className="gutter-row">
                        <Card title={"Average Visit Time"}>
                            <h2>7 min</h2>
                            <paragraph><Tag color="red"><Icon type="arrow-down"/>5</Tag> less than yesterday</paragraph>
                        </Card>
                    </Col>
                    <Col xl={{span: 5}} lg={{span: 5}} md={{span: 6}} sm={{span: 8}} xs={{span: 12}}
                         className="gutter-row">
                        <Card title={"Average Wait Time"}>
                            <h2>16 min</h2>
                            <paragraph><Tag color="red"><Icon type="arrow-down"/>2</Tag> less than yesterday</paragraph>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row gutter={16}>
                    <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}
                         className="gutter-row">
                        <Card title={"Average Visit Time Per Person"}>
                            <Line data={datasets.chartData} options={datasets.options}/>
                        </Card>
                    </Col>
                    <Col xl={{span: 12}} lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}
                         className="gutter-row">
                        <Card title={"Total Visits"}>
                            <Bar data={datasets2.chartData} options={datasets2.options}/>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}
import axios from "./axios"
import {authHeader} from "../helpers/auth-header"
import {config} from "../helpers/config"

class LicenseService {
    static Instance() {
        return new LicenseService()
    }

    generateLicense(req) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            data: JSON.stringify(req),
            url: "/api/license"
        };
        return axios(request);
    }

    downloadLicense(record) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: "/api/license/download/" + record.id
        };
        return axios(request);
    }

    reissueLicense(record) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: "/api/license/reissue/" + record.id
        };
        return axios(request);
    }

    reissuePermanentLicense(record) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: "/api/license/reissuepermanent/" + record.id
        };
        return axios(request);
    }

    listLicenses() {
        return axios.post('/api/license/list');
    }

}

export default LicenseService.Instance()
import React, { Component } from "react";
import {
    Card,
    Col,
    Collapse,
    Empty,
    Icon,
    Pagination,
    Row,
    Table,
    Tag,
    message, Button, Menu, Dropdown, Typography, Input,
    notification, Select
} from 'antd';
import GenericFilter from "../../components/GenericFilter";
import Moment from "react-moment";
import vidsService from "../../services/VidsService";
import feedService from "../../services/FeedService";
import { saveAs } from 'file-saver';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import CommonService from "../../services/CommonService";
import TrafficIncidentService from "../../services/TrafficIncidentService";
import Switch from "antd/es/switch";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";

const { Text } = Typography;

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;


export default class HighwayIncidentView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            loading: true,
            layout: "list",
            incidentType: "",
            location: "All",
            showItems: 3,
            feedId: 0,
            feedOptions: [],
            incidentOptions: [],
            incidents: {},
            playVideo: false,
            filter: {
                page: 1,
                pageSize: 24,
                incidentType: "",
                feedId: 0,

            },
            autoRefresh: true,
            vidsSetting: {
                autoRefresh: false,
                directionVisible: false,
                speedVisible: false,

                dateTimeVisible: false,
                locationVisible: false,

                autoRefreshInSeconds: 0,
            },
            selectedEvent: null,
        };


        this.refresh = this.refresh.bind(this);
        this.fetchVidsSetting = this.handleFetchvidsSetting.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.handleIncidentMenuClick = this.handleIncidentMenuClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLocationMenuClick = this.handleLocationMenuClick.bind(this);
        this.getFeeds = this.getFeeds.bind(this);
        this.autoRefreshSwitchChange = this.autoRefreshSwitchChange.bind(this);
    }

    componentDidMount() {
        this.myInstant = setInterval(() => {
            this.refresh()
        }, 10000);
        this.refresh();
        this.getFeeds();
        this.getIncidentTypes();
        this.handleFetchvidsSetting();
    }

    componentWillUnmount() {
        clearInterval(this.myInstant);
    }

    autoRefreshSwitchChange() {
        if (this.state.autoRefresh === false) {
            this.setState({ autoRefresh: true });
            this.myInstant = setInterval(() => {
                this.refresh()
            }, 10000);
        }
        else {
            this.setState({ autoRefresh: false });
            clearInterval(this.myInstant);
        }
    }

    refresh() {
        this.UpdateIncidentFilter();
        this.UpdateLocationFilter();
        vidsService.getIncidents(this.state.filter).then(request => {
            this.setState({ "incidents": request.data, loading: false })

        })
    }
    handleFetchvidsSetting() {
        vidsService.getVidsSettings().then(res => {
            console.log({ res });
            let vidsSetting = res.data;
            this.setState({ vidsSetting });
            if (vidsSetting.autoRefresh) {
                this.myInstant = setInterval(() => {
                    this.refresh()
                }, vidsSetting.autoRefreshInSeconds * 1000);
            }
        }).catch(error => {
            console.log({ error });
            this.setState({ loading: false });
            message.error(CommonService.handleErrorMessage(error));
        })
    }
    //cant use refresh to read from state as state may not have been set
    refreshNow() {

        this.UpdateIncidentFilter();
        this.UpdateLocationFilter();
        vidsService.getIncidents(this.state.filter).then(request => {
            this.setState({ "incidents": request.data, loading: false })
        })
    }

    archiveEvent(event) {
        vidsService.archiveIncident(event).then(request => {
            this.refresh();
        })
    }

    handleFilterChange(filter) {
        this.setState({ filter })
    }

    handleLayoutChange(data) {
        this.setState({ layout: data })
    }

    handleOnClick() {
        this.setState({
            visible: false,
        });
    };

    handleRefresh() {
        this.refresh();
    }

    onPageChange(page, pageSize) {
        let filter = this.state.filter;
        filter.page = page;
        filter.pageSize = pageSize;
        this.refreshNow(filter)
    }

    onPageSizeChange(current, pageSize) {
        let filter = this.state.filter;
        filter.pageSize = pageSize;
        this.refreshNow(filter);
    }

    handleIncidentMenuClick(choice) {
        if (choice.item.props.children == "All") {
            this.setState({ incidentType: "" }, ()=>{
                this.refresh();
            })
        } else
            this.setState({ incidentType: choice.item.props.children }, ()=>{
                this.refresh();
            });
    }

    handleLocationMenuClick(choice) {
        let feedId;
        if (choice.item.props.children == "All") {
            feedId = 0;
            this.setState({ feedId: feedId })
            this.setState({ location: "All" }, ()=>{
                this.refresh();
            })
        } else {
            feedId = choice.item.props.id;
            this.setState({ feedId: feedId });
            this.setState({ location: choice.item.props.children },()=>{
                this.refresh();
            });


        }
    }

    downloadVideo(e) {
        vidsService.downloadVideo(e.id)
            .then((response) => {
                saveAs(response.data, e.timeStamp + ".mp4");
            }).catch(error => {
                alert("Something went wrong!");
            })
    }

    downloadImage(e) {
        vidsService.downloadScreenshot(e.id)
            .then((response) => {
                saveAs(response.data, e.timeStamp + ".jpg");
            }).catch(error => {
                alert("Something went wrong!");
            })
    }

    handleChange = (value) => {
        console.log(value, "value")
        this.setState({
                showItems: value.key
              })
      };


    render() {

        let { layout, vidsSetting } = this.state.layout;


        const incidentMenu = (
            <Menu onClick={this.handleIncidentMenuClick}>
                <Menu.Item key="1">
                    All
                </Menu.Item>
                {(this.state.incidentOptions || []).map((type) =>
                    <Menu.Item key={type}>
                        {type}
                    </Menu.Item>
                )}
            </Menu>
        );


        const locationMenu = (

            <Menu onClick={this.handleLocationMenuClick}>
                <Menu.Item key={1}>All</Menu.Item>
                {(this.state.feedOptions || []).map((feed) =>
                    <Menu.Item key={feed} id={feed.id}>
                        {feed.site + ">" + feed.location}
                    </Menu.Item>
                )}
            </Menu>

        );


        return (
            <div>
              <Heading>Video Incident Detection System</Heading>
               <Timer/>
                <Collapse bordered={false} defaultActiveKey={['1']}>
                    <span>&nbsp;&nbsp;</span>
                    <Panel header="Filter" key="1">
                        Location: <Dropdown overlay={locationMenu}>
                            <Button color="#f50">
                                {this.state.location}<Icon type="down" />
                            </Button>
                        </Dropdown>
                        <span>&nbsp;&nbsp;</span>
                        Incident Type: <Dropdown overlay={incidentMenu}>
                            <Button color="#f50">
                                {this.state.incidentType}<Icon type="down" />
                            </Button>
                        </Dropdown>
                        <span style={{ float: "right" }}>
                            Auto Refresh &nbsp; &nbsp; <Switch defaultChecked onChange={this.autoRefreshSwitchChange} />
                        </span>
                        <br />
                        <br />
                        <GenericFilter handleRefresh={this.refresh} filter={this.state.filter} layout={layout}
                            handleFilterChange={this.handleFilterChange}
                            handleLayoutChange={this.handleLayoutChange} />
                    </Panel>
                </Collapse>
                <div>
                    {layout === "table" ? (this.renderTable()) : (this.renderGrid())}
                </div>
            </div>)
    }

    getFeedString(event) {
        let result = "NA";
        if (event && event.feed) {
            result = event.feed.site + " | " + event.feed.location + " | " + event.feed.name;
        }
        return result;
    }

    renderGrid() {


        if (this.state.loading || !this.state.incidents || this.state.incidents.totalPage === 0) {
            return <Empty description={false} />
        }

        let events = this.state.incidents.events;
        const selectedEvents = events.slice(0, this.state.showItems)
        let count = this.state.incidents.totalPages * this.state.incidents.pageSize;


        return <div style={{ background: '#ECECEC', padding: '5px' }}>
            <Row>
                {
                    selectedEvents.map((event, index) =>
                        <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} key={index}>
                            <Card
                                style={{ margin: "5px" }}
                                title={
                                    <div>
                                        <Tag color="#f50">{event.incidentType}</Tag>
                                        {
                                            event.vehicleClass
                                                ? <Tag color="#f50">{event.vehicleClass}</Tag>
                                                : null
                                        }
                                    </div>
                                }

                                extra={<Dropdown overlay={<Menu>
                                    {
                                        (event.incidentImage != "") ?
                                            <Menu.Item key="1">
                                                <a
                                                    title={"click here to download"}
                                                    onClick={() => this.downloadImage(event)}
                                                ><Icon type="download" />{' '} Image</a>
                                            </Menu.Item>
                                            : null
                                    }
                                    {(event.incidentVideo != "") ?
                                        <Menu.Item key="2">
                                            <a
                                                title={"click here to download"}
                                                onClick={() => this.downloadVideo(event)}
                                            ><Icon type="download" />{' '} Video</a>
                                        </Menu.Item>
                                        : null
                                    }
                                    <Menu.Item key="3">
                                        <Button type="danger" onClick={() => this.archiveEvent(event)}><Icon
                                            type="warning" />{' '}
                                            Archive
                                        </Button>
                                    </Menu.Item>

                                </Menu>}>
                                    <Button>
                                        More <Icon type="down" />
                                    </Button>
                                </Dropdown>}
                                bordered={true}

                            >


                                <Player
                                    playsInline
                                    poster={"/public/vids/image/" + event.id + "/image.jpg"}
                                    src={"/public/vids/video/" + event.id + "/video.mp4"}
                                />
                                <div style={{ textAlign: "center", marginTop: "5px" }}>
                                    <Text code><Icon type="schedule" /> <Moment
                                        format="ll">{event.incidentDate}</Moment>{' '}|{' '}<Moment
                                            format="LTS">{event.incidentDate}</Moment></Text>
                                </div>
                                {
                                    event.lpr
                                        ? <div style={{ textAlign: "center", marginTop: "2px" }}>
                                            <div>
                                                <Text code>
                                                    LPR: {event.lpr}
                                                </Text>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div style={{ textAlign: "center", marginTop: "2px" }}>
                                    <div>
                                        <Text code>
                                            <Icon type="environment" />
                                            {this.getFeedString(event)}
                                        </Text>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    )
                }
            </Row>
            <Row style={{margin:"10px"}} >
            <Col xl={4} lg={4} md={6} sm={12}>
                                 <Select
                                    labelInValue
                                     defaultValue={{ key: 'Select Row' }}
                                      style={{ width: 120 }}
                                    onChange={this.handleChange}
                                  >
                                       <Option value={3}>Row 1</Option>
                                        <Option value={6}>Row 2</Option>
                                        <Option value={9}>Row 3</Option>
                                        <Option value={12}>Row 4</Option>
                                    </Select>
                            </Col>
                            <Col xl={20} lg={20} md={6} sm={12}>
                            <div style={{ textAlign: "right" }}>
                <Pagination onChange={this.onPageChange} onShowSizeChange={this.onPageSizeChange} showSizeChanger
                    showQuickJumper
                    defaultCurrent={1} total={count} current={this.state.filter.page}
                    pageSize={this.state.filter.pageSize} />
            </div>
            </Col>
            </Row>
        </div>
    }

    renderTable() {


        if (this.state.loading || !this.state.incidents || this.state.incidents.totalPage === 0) {
            return <Empty description={false} />
        }

        let events = this.state.incidents.events;
        let count = this.state.incidents.totalPages * this.state.incidents.pageSize;

        const paginationOptions = {
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: this.onPageSizeChange,
            onChange: this.onPageChange,
            total: count
        };

        const pagination = {
            ...paginationOptions,
            total: count,
            current: this.state.filter.page,
            pageSize: this.state.filter.pageSize
        };

        return (
            <Table dataSource={events} pagination={pagination}>
                <Column title="Location" dataIndex="source" key="location"
                    render={(dataIndex, event, index) => {
                        let result = null;
                        if (event.feed) {
                            result = <div>
                                <div>{event.feed.location}</div>
                                <div>{event.feed.site}</div>
                                <div>{event.feed.name}</div>
                            </div>
                        } else {
                            result = <Text>{event.source}</Text>
                        }
                        return result;
                    }
                    } />
                <Column title="Start Date" dataIndex="eventDate" key="eventDate"
                    render={eventDate => (
                        <div>
                            <div>
                                <Moment format="L">{eventDate}</Moment>
                            </div>
                            <Moment format="LT">{eventDate}</Moment>
                        </div>
                    )} />
                <Column title="End Date" dataIndex="eventEnd" key="eventEnd"
                    render={eventEnd => (
                        <div>
                            <div>
                                <Moment format="L">{eventEnd}</Moment>
                            </div>
                            <Moment format="LT">{eventEnd}</Moment>
                        </div>
                    )} />
                <Column title="Type" dataIndex="incidentType" key="incidentType"
                    render={incidentType => ({ incidentType })} />
                <Column title="Vehicle Class" dataIndex="vehicleClass" key="vehicleClass"
                    render={vehicleClass => ({ vehicleClass })} />
                <Column title="Action"
                    key="action"
                    render={(text, event) => (
                        <Button type="danger" onClick={() => this.archiveEvent(event)}><Icon type="warning" />{' '}
                            Archive</Button>
                    )}
                />

            </Table>
        )
    }

    UpdateIncidentFilter() {
        let filter = this.state.filter;
        filter.incidentType = this.state.incidentType;
        this.setState({ filter: filter })
    }

    UpdateLocationFilter() {
        let filter = this.state.filter;
        filter.feedId = this.state.feedId;
        this.setState({ filter: filter });

    }

    getFeeds() {
        feedService.getFeeds().then(response => {
            this.setState({ feedOptions: response.data });
        }).catch(error => {
            notification.open({
                message: 'Something went wrong ',
                discription: error
            });
        })
    }


    getIncidentTypes() {

        TrafficIncidentService.getIncidentTypes().then(response => {
            this.setState({ incidentOptions: response.data });

        }).catch(error => {
            notification.open({
                message: 'Something went wrong ',
                discription: error
            });
        })

    }

}

import axios from "./axios";
import { authHeader } from '../helpers/auth-header';
import { config } from '../helpers/config'

class IncidentReportService {

    static Instance() {
        return new IncidentReportService()
    }

    downloadIncidentEventsReport(req) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            data: JSON.stringify(req),
            url: config.apiUrl + 'api/report/incident/events/all'
        };
        return axios(request);
    }

    downloadIncidentEventSummaryReport(req) {

        const request = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'blob',
            data: JSON.stringify(req),
            timeout:600000,
            url: config.apiUrl + 'api/report/incident/events/summary/day-wise'
        };
        return axios(request);
    }

}

export default IncidentReportService.Instance()
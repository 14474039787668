import axios from "./axios";

class VasdService {

    static Instance() {
        return new VasdService()
    }

    getEvents(filter) {
        return axios.post('/api/vasd/events', filter);
    }


    archiveEvent(event) {
        return axios.delete('/api/vasd/' + event.id);
    }


    updateEvent(event) {
        return axios.put('/api/vasd/event', event);
    }
    getVasdSettings() {
        return axios.get("/api/vasd/settings");
    }

    saveVasdSettings(req) {
        return axios.post("/api/vasd/settings", req);
    }

}

export default VasdService.Instance()
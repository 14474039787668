import React, { Component } from 'react';

import './App.css';
import { Layout, notification, Modal, Tag, Icon, Card, Typography, message } from 'antd';
import Sidebar from "./components/Sidebar";
import Headbar from "./components/Headbar";
import Footerbar from "./components/Footerbar";
import PrivateRoute from "./components/PrivateRoute";

import HomeView from "./views/HomeView";
import FeedView from "./views/feed/FeedView";
import { Route } from "react-router-dom";
import DeviceView from "./views/DeviceView";
import UserListView from "./views/UserListView";
import DeviceConfigView from "./views/DeviceConfigView";
import AlertConfig from "./views/alerts/AlertConfig";
import SurveyCreation from "./views/avc/SurveyCreation";
import GenerateToken from "./views/GenerateToken";

import UserService from "./services/UserService";
import { EventBus } from "./components/event"
import LoginView from "./views/LoginView";
import TriggerView from "./views/TriggerView";
import AnprView from "./views/anpr/AnprView";
import TrafficIncidentView from "./views/TrafficIncidentView";
import IncidentRepeatedView from "./views/incidents/IncidentRepeatedView";
import IncidentHotlistView from "./views/IncidentHotlistView";
import ParkingDashboardView from "./views/parking/ParkingDashboardView";
import ParkingConsoleView from "./views/parking/ParkingConsoleView";
import PgsReportView from "./views/parking/ParkingReportView";
import ParkingInOutView from "./views/parking/ParkingInOutView";
import PeopleCounting from "./views/PeopleCount/PeopleCounting";
import ApcDashboard from "./views/PeopleCount/ApcDashboard";

import QueuesView from "./views/queueManagement/QueuesView";
import QueueManagementDashboard from "./views/queueManagement/QueueManagementDashboard";
import AtccSummaryView from "./views/atcc/AtccSummaryView";
import AtccReportView from "./views/atcc/AtccReportView";
import HighwayIncidentView from "./views/vids/HighwayIncidentView";
import HighwayIncidentDashboardView from "./views/vids/HighwayIncidentDashboardView";
import AnprReportView from "./views/anpr/AnprReportView";
import FaceRegisterView from "./components/facerec/FaceRegisterView";

import ConfigView from "./views/Polygon/ConfigView";
import RegisteredView from "./components/facerec/RegisteredView";
import FrsEventView from "./components/facerec/FrsEventView";

import AtccGridView from "./views/atcc/AtccGridView";
import { history } from "./helpers/history";
import RoleView from "./views/RoleView";
import Moment from "react-moment";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import EditCurrentOrg from "./views/Org/EditCurrentOrg";
import OrgService from "./services/OrgService";
import SurveyReports from "./views/avc/SurveyReports";
import AvcReportView from "./views/avc/AvcReportView";
import SynvisionLicenseView from "./views/SynvisionLicenseView";
import AnprSpeedSettings from "./views/AnprSpeedSettings";
import IncidentReportView from "./views/incidents/IncidentReportView";
import AnprSettings from "./views/anpr/AnprSettingsView";
import { AtccSettings } from './views/atcc/AtccSettingsView';
import VidsSettings from './views/vids/VidsSettingsView';
import VidsReport from "./views/vids/VidsReport";
import VasdSettings from "./views/vasd/VasdSettingsView";
import FeedDashboard from "./views/feed/FeedDashboard";
import VasdView from "./views/vasd/VasdView";
import VasdReportView from "./views/vasd/VasdReportView";
import ParkingCompanyView from "./views/parking/ParkingCompanyView";
import BuildingView from "./views/parking/BuildingView";
import CompanyView from "./views/parking/CompanyView";
import VehicleView from "./views/parking/VehicleView";

const { Text } = Typography;

const { Content } = Layout;


var stompClient = null;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            org: null,
            loggedIn: false,
            channelConnected: false,
            showAlert: false,
            alert: {}
        };

        this.onConnected = this.onConnected.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        EventBus.subscribe('login-logout', (event) => this.refreshMenu(event))
    }

    componentDidMount() {
        this.refreshMenu();
        this.connect();
    }

    updateOrg = () => {
        OrgService.getOrgDetails()
            .then(res => {
                const org = { ...res.data };
                const websiteTitle = document.getElementById("website-title");
                if(websiteTitle){
                    websiteTitle.innerHTML = org.name;
                    websiteTitle.innerText = org.name;
                    websiteTitle.text = org.name;
                    websiteTitle.textContent = org.name;
                }
                document.head.getElementsByTagName("link")[0].href = "/public/org/logo/" + org.id;
                document.head.getElementsByTagName("link")[1].href = "/public/org/logo/" + org.id;
                this.setState({ org })
            })
            .catch(e => {
                message.error("Something Went Wrong");
                console.log(e)
            })
    };

    refreshMenu() {

        UserService.tokenValid()
            .then(data => {
                const loggedIn = UserService.isLoggedIn();
                if (loggedIn) {
                    this.updateOrg()
                }
                this.setState({ loggedIn });
            })
            .catch(error => {
                localStorage.clear();
                this.setState({ loggedIn: false });
                history.push("/#/login")
                console.log("Session Expired !! Login Again");
            })

    }

    componentWillUnmount() {
        //  this.disconnect();
    }

    connect() {
        const Stomp = require('stompjs')

        var SockJS = require('sockjs-client')

        SockJS = new SockJS('/ws')

        stompClient = Stomp.over(SockJS);

        stompClient.connect({}, this.onConnected, this.onError);
    }

    onConnected() {
        console.log("connected!")
        this.setState({
            channelConnected: true
        })
        stompClient.subscribe('/alert', this.onMessageReceived);
    }

    onError() {
        console.log("error connecting!")
    }

    getFrsAlert(alert) {
        const args = {
            message: alert.message,
            description: <Card
                style={{ margin: "5px" }}
                title={
                    <div>
                        {(alert.person) ? <Tag color="#f50">{alert.person.type}</Tag> : ""}
                        {(alert.person) ? <Tag color="#f50">{alert.person.accessType}</Tag> : ""}
                        <br />
                        <Text
                            type="secondary">{(alert.person) ? "ID: " + alert.person.pid : ""}</Text>
                        <Text
                            type="secondary">{(alert.person) ? "  Name: " + alert.person.name : ""}</Text>

                    </div>
                }
                bordered={true}
            >
                <div style={{ textAlign: "center" }}>
                    <img alt="face" style={{ width: 100, height: 100, borderRadius: "50%" }}
                        src={"/public/frs/event/face/" + alert.uid + "/image.jpg"} />
                </div>
                <div style={{ marginTop: "5px", textAlign: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <img alt="person" style={{ width: 200, height: 200 }}
                            src={"/public/frs/event/full/" + alert.uid + "/image.jpg"} />
                    </div>
                </div>

            </Card>,
            duration: 0,
        };
        return args;
    }

    getApcAlert(response) {
        const alert = JSON.parse(response.body);
        EventBus.publish("apc-event", ()=> {})
        const args = {
            message: alert.message,
            description: <Card
                style={{ margin: "5px" }}
                title={
                    <div>
                        <Tag color={alert.incidentVideo === "novideo.jpg" ? "#f00" : "#f50"}>{alert.location}</Tag>
                        <br />
                        <Text code><Icon type="schedule" /> <Moment
                            format="ll">{alert.incidentDate}</Moment>{' '}|{' '}<Moment
                                format="LTS">{alert.incidentDate}</Moment></Text>

                    </div>
                }
                bordered={true}
            >

                <div style={{ textAlign: "center" }}>
                    {
                        <img alt="people-event"
                        style={{
                            width: '100%',
                            height: 'auto'
                        }}
                        src={"/public/apc/people/" + alert.id + "/image.jpg"} />
                    }

                </div>
            </Card>,
            duration: 0,
        };
        return args;
    }

    getVidsAlert(response) {
        const alert = JSON.parse(response.body);
        const args = {
            message: alert.message,
            description: <Card
                style={{ margin: "5px" }}
                title={
                    <div>
                        <Tag color={alert.incidentImage === "novideo.jpg" ? "#f00" : "#f50"}>{alert.type}</Tag>
                        <Tag color={alert.incidentVideo === "novideo.jpg" ? "#f00" : "#f50"}>{alert.location}</Tag>
                        <br />
                        <Text code><Icon type="schedule" /> <Moment
                            format="ll">{alert.incidentDate}</Moment>{' '}|{' '}<Moment
                                format="LTS">{alert.incidentDate}</Moment></Text>

                    </div>
                }
                bordered={true}
            >

                <div style={{ textAlign: "center" }}>
                    {
                        alert.type === "Hotlisted"
                            ? <img alt="event"
                                style={{
                                    width: '100%',
                                    height: 'auto'
                                }}
                                src={"/public/anpr/vehicle/" + alert.id + "/image.jpg"}
                            />
                            : <Player
                                playsInline
                                poster={"/public/vids/image/" + alert.id + "/image.jpg"}
                                src={"/public/vids/video/" + alert.id + "/video.mp4"}
                            />
                    }

                </div>
            </Card>,
            duration: 0,
        };
        return args;
    }
    onMessageReceived(payload) {
        const isLoggedIn = this.state.loggedIn;
        if (!isLoggedIn) return;
        let alert = JSON.parse(payload.body);
        console.log("rcvd alert", alert);
        const args = alert.type === "APC" ? this.getApcAlert(payload) : this.getVidsAlert(payload);
        notification.open(args);
    }


    render() {

        const isLoggedIn = this.state.loggedIn;
        const org = this.state.org;
        const showAlert = this.state.showAlert;
        const alert = this.state.alert;
        const sideBar = isLoggedIn && org ? <Sidebar org={org} /> : null;
        const header = isLoggedIn && showAlert ? <Headbar alert={alert} isLoggedIn={isLoggedIn} /> : null;
        const footer = org ? <Footerbar org={org} /> : null;

        return (
            <div className="App">

                <Layout style={{ minHeight: '100vh' }}>

                    {sideBar}

                    <Layout>
                        {header}
                        <Content style={{
                            overflow: "auto",
                            height: "100vh"
                        }}>
                            <div style={{ paddingLeft: "3px" }}>
                                <Route path='/login' exact={true} component={LoginView} />
                                <PrivateRoute path='/' exact={true} component={HomeView} />
                                <PrivateRoute path='/incidents' exact={true} component={TrafficIncidentView} />
                                <PrivateRoute path='/incidents/hotlisted' exact={true} component={IncidentHotlistView} />
                                <PrivateRoute path='/incidents/repeated' exact={true} component={IncidentRepeatedView} />
                                <PrivateRoute path='/incident/report' exact={true} component={IncidentReportView} />
                                <PrivateRoute path='/anpr/report' exact={true} component={AnprReportView} />
                                <PrivateRoute path='/anpr' exact={true} component={AnprView} />
                                <PrivateRoute path='/anpr/settings' exact={true} component={AnprSettings} />
                                <PrivateRoute path='/user' exact={true} component={UserListView} />
                                <PrivateRoute path='/feed' exact={true} component={FeedView} />
                                <PrivateRoute path='/feed-dashboard' exact={true} component={FeedDashboard} />
                                <PrivateRoute path='/trigger' exact={true} component={TriggerView} />
                                <PrivateRoute path='/device' exact={true} component={DeviceView} />
                                <PrivateRoute path='/device/conf' exact={true} component={DeviceConfigView} />
                                <PrivateRoute path='/people-counting/events' exact={true} component={PeopleCounting} />
                                <PrivateRoute path='/roles' exact={true} component={RoleView} />
                                <PrivateRoute path='/admin/auth/synvision/token/generate' exact={true} component={GenerateToken} />
                                <PrivateRoute path='/generatelicense' exact={true} component={SynvisionLicenseView} />
                                <PrivateRoute path='/edit/anprspeed' exact={true} component={AnprSpeedSettings} />

                                {/* PGS is parking guideance and management system */}
                                <PrivateRoute path='/pgs/dashboard' exact={true} component={ParkingCompanyView} />
                                <PrivateRoute path='/pgs/console' exact={true} component={ParkingConsoleView} />
                                <PrivateRoute path='/pgs/inout' exact={true} component={ParkingInOutView} />
                                <PrivateRoute path='/pgs/reports' exact={true} component={PgsReportView} />
                                <PrivateRoute path='/people-counting/Dashboard' exact={true} component={ApcDashboard} />
                                <PrivateRoute path='/pgs/buildings' exact={true} component={BuildingView} />
                                <PrivateRoute path='/pgs/companies' exact={true} component={CompanyView} />
                                <PrivateRoute path='/pgs/vehicles' exact={true} component={VehicleView} />



                                <PrivateRoute path='/atcc/summary' exact={true} component={AtccSummaryView} />
                                <PrivateRoute path='/atcc' exact={true} component={AtccGridView} />
                                <PrivateRoute path='/atcc/report' exact={true} component={AtccReportView} />
                                <PrivateRoute path='/atcc/settings' exact={true} component={AtccSettings} />

                                <PrivateRoute path='/alertConfig' exact={true} component={AlertConfig} />

                                {/* vids */}
                                <PrivateRoute path='/vids' exact={true} component={HighwayIncidentView} />
                                <PrivateRoute path='/vids/dashboard' exact={true} component={HighwayIncidentDashboardView} />
                                <PrivateRoute path='/vids/report' exact={true} component={VidsReport} />
                                <PrivateRoute path='/vids/settings' exact={true} component={VidsSettings} />

                                <PrivateRoute path='/feedStream' exact={true} component={ConfigView} />

                                {/* face rec */}
                                <PrivateRoute path='/frs/register' exact={true} component={FaceRegisterView} />
                                <PrivateRoute path='/frs/frsuser' exact={true} component={RegisteredView} />
                                <PrivateRoute path='/frs/frsevents' exact={true} component={FrsEventView} />

                                {/*org change*/}
                                <PrivateRoute path={'/edit/org'} exact={true} component={EditCurrentOrg} />

                                <PrivateRoute path={'/edit/survey'} exact={true} component={SurveyCreation} />
                                <PrivateRoute path={'/surveyReports'} exact={true} component={SurveyReports} />
                                <PrivateRoute path='/avc/reports' exact={true} component={AvcReportView} />

                                <PrivateRoute path='/vasd/report' exact={true} component={VasdReportView} />
                                <PrivateRoute path='/vasd' exact={true} component={VasdView} />
                                <PrivateRoute path='/vasd/settings' exact={true} component={VasdSettings} />

                                <PrivateRoute path='/queue-management/events' exact={true} component={QueuesView} />
                                <PrivateRoute path='/queue-management/dashboard' exact={true} component={QueueManagementDashboard} />

                            </div>
                        </Content>
                        {footer}
                    </Layout>
                </Layout>

            </div>
        );

    }
}

export default App;
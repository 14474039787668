import axios from "./axios"
import {authHeader} from "../helpers/auth-header";
import {config} from "../helpers/config";

class AvcService {

    static Instance() {
        return new AvcService()
    }

    saveSurvey(survey) {
        return axios.post('/api/survey/', survey)
    }

    fetchSurveys() {
        return axios.get('/api/survey/list')
    }

    generateReport(surveyId) {

        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: config.apiUrl + 'api/report/avc/survey?surveyId=' + surveyId
        };
        return axios(request);
    }

    getAvcReport(req) {
        let path = 'api/report/vids/incidents/all';

        switch (req.reportType) {
            case "All Vehicles Traffic Events":
                path = 'api/report/avc/events/all';
                break;
            case "Vehicles Traffic Events Summary":
                path = 'api/report/avc/events/summary/day-wise';
                break;

        }
        const request = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            data: JSON.stringify(req),
            url: config.apiUrl + path
        };
        return axios(request);
    }

}

export default AvcService.Instance()
import React from "react"
import { Component } from "react";
import {
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Form, Icon,
    Input,
    Menu,
    message,
    Modal,
    notification,
    Row,
    Table,
    Tag, Typography, Checkbox, Select, Collapse
} from "antd";
import '../form.css';
import RoleService from "../services/RoleService";
import UserService from "../services/UserService";
import Column from "antd/lib/table/Column";
import { EventBus } from "../components/event";
import CommonService from "../services/CommonService";
import Heading from "../components/Heading";
import Timer from "../components/Timer";

const { Panel } = Collapse;
const { Text } = Typography;
const { confirm } = Modal;
let flag = false;
export default class RoleView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            formVisible: false,
            roles: [],
            mode: "Add",
            role: { id: "", name: "", privileges: [], menuItems: '', menuArray: [] },
            privileges: [],
            menuArray: [],
        }
        EventBus.subscribe('role-refresh', (event) => this.refresh())
        this.close = this.close.bind(this);
        this.addNewRole = this.addNewRole.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.getMenuItems();
    }

    getMenuItems() {
        UserService.getMenuItems().then(response => {
            this.setState({ menuArray: response.data })
        },
            error => {
                message.error(error.response.data.message);
            });
    }

    refresh() {
        UserService.getRoles().then(request => {
            let roles = request.data;
            roles.forEach(role => {
                role.menuArray = [];
                if (role.menuItems) {
                    let menuArray = role.menuItems.split(',');
                    role.menuArray = menuArray;
                }
            })
            this.setState({ roles, loading: false })
        })
    }

    refreshNow() {
        this.refresh();
    }

    close() {
        this.setState({ "formVisible": false })
    }

    showDeleteConfirm(roleId, refresh) {
        confirm({
            title: 'Are you sure you want to Delete this Role',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                RoleService.removeRole(roleId)
                    .then(() => {
                        refresh();
                        message.success("Deleted Successfully!")
                    }).catch(error => {
                        let msg = "Something went wrong!";
                        if (error && error.response && error.response.data && error.response.data.message) {
                            msg = error.response.data.message;
                            message.warn(msg);
                        }

                    });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    addNewRole() {
        this.setState({ formVisible: true, role: { name: "", privileges: [] }, mode: "Add" })
        flag = false;
    }

    editRole(RoleId) {
        this.setState({ "formVisible": true, "mode": "Edit" })
        flag = true;
        RoleService.getRole(RoleId)
            .then(response => {
                let role = response.data;
                role.menuArray = [];
                if (role.menuItems) {
                    let menuArray = role.menuItems.split(',');
                    role.menuArray = menuArray;
                }
                this.setState({ role });
            },
                error => {
                    message.error(error.response.data.message);
                });
    }

    render() {
        let roles = this.state.roles
        const WrappedRoleForm = Form.create({ name: 'role_form' })(AddRoleForm);
        const formVisible = this.state.formVisible;
        if (this.state.loading || !this.state.roles || this.state.roles.length === 0) {
            roles = []
        }
        return (
            <div>
               <Heading>Role</Heading>
               <Timer/>
                <Row style={{marginTop:"30px"}} gutter={2}>
                    <Col span={2}>
                        <Button type="primary" onClick={this.addNewRole}>
                            <Icon type="plus-circle" /> New Role
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={16}>
                        <Card
                            className="limitable"
                            bodyStyle={{ padding: "0px", width: "100%" }}
                        >
                            <Table dataSource={this.state.roles} pagination={false} scroll={{ x: true }}>
                                <Column align="center" title="Role" dataIndex="name" key="name"
                                    render={(name) => name} />
                                <Column title="Privileges" dataIndex="privileges"
                                    render={privileges => (
                                        <>
                                            {privileges.map(privilege => {
                                                return (
                                                    <Tag color="blue" key={privilege}>
                                                        {privilege}
                                                    </Tag>
                                                );
                                            })}
                                        </>
                                    )
                                    } />
                                <Column title="Actions" render={(record) => (
                                    <div>
                                        <Icon type="edit" onClick={() => this.editRole(record.id)} />
                                        <Divider type="vertical" />
                                        <Icon type="delete" style={{ color: "#ff0000" }}
                                            onClick={() => this.showDeleteConfirm(record.id, this.refresh)} />
                                    </div>
                                )} />

                            </Table>
                        </Card>
                    </Col>


                    {formVisible ?
                        <Col span={8}>
                            <WrappedRoleForm role={this.state.role} close={this.close}
                                menuArray={this.state.menuArray} />
                        </Col>
                        : null
                    }
                </Row>
            </div>)
    }


}

class AddRoleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: { name: "", privileges: [] },
            PrivilegeOptions: [],
            PrivilegeChoice: this.props.role.privileges,
            submitted: false,
            loading: false,
            menuItemsArray: this.props.role.menuArray,
            formErrors: {},
            isOpenMenuCard: true,
            isOpenPrivilegeCard: true
        }
        this.getAllPrivileges = this.getAllPrivileges.bind(this);
        this.handlePrivilegeMenuClick = this.handlePrivilegeMenuClick.bind(this);
        this.removePrivilegeChoice = this.removePrivilegeChoice.bind(this);
        this.close = this.close.bind(this);
        this.closeMenuCard = this.closeMenuCard.bind(this);
        this.closePrivilegeCard = this.closePrivilegeCard.bind(this);
    }

    componentDidMount() {
        this.getAllPrivileges();
    }

    handleSubmit(e, formName) {

        e.preventDefault();
        const form = this.props.form;
        let role = {};
        role.name = form.getFieldValue("name");
        role.id = this.props.role.id;
        role.privileges = this.state.PrivilegeChoice;
        role.menuArray = this.state.menuItemsArray;

        if (role.menuArray) {
            role.menuItems = role.menuArray.join();
        }


        let formErrors = {};
        let valid = true;

        if (formName === 'Role') {
            if (!role.name) {
                formErrors.role = "Name missing";
                valid = false;
            }
        }

        else if (formName === 'Privilege') {
            if (role.privileges.length === 0) {
                formErrors.privileges = "Privileges not assigned to role";
                valid = false;
            }
        }

        else if (formName === 'Menu') {
            if (role.menuArray.length === 0) {
                formErrors.menu = "Missing menu";
                valid = false;
            }
        }

        if (!valid) {
            this.setState({ formErrors: formErrors });
            return
        }

        console.log('saving role', role);
        this.setState({ submitted: true, loading: true });


        this.setState({ formErrors: {} });
        RoleService.addRole(role, flag).then(response => {
            if (flag) {
                message.success("Role updated")
                this.close()
            } else {
                message.success("Role Added")
            }

            EventBus.publish('role-refresh', {})


        }).catch(error => {
            let msg = "Something went wrong!";
            if (error && error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message;
                message.warn(msg);
            }

        });
    }

    close() {
        this.props.close();
    }

    handleCheckboxChange(privilegeName, checked) {

        let { PrivilegeChoice } = this.state;
        if (checked) {
            if (!PrivilegeChoice.includes(privilegeName)) {
                PrivilegeChoice.push(privilegeName);
            }
        }

        else {
            PrivilegeChoice = PrivilegeChoice.filter(item => item !== privilegeName);
        }


        this.setState({ PrivilegeChoice });


    }

    isChecked(privilegeName) {
        let { PrivilegeChoice } = this.state;
        if (PrivilegeChoice.includes(privilegeName)) {
            return true;
        }
        return false;
    }

    selectAll(checked) {
        let { PrivilegeOptions, PrivilegeChoice } = this.state;
        if (checked) {
            PrivilegeOptions.map(privilege => {
                PrivilegeChoice.push(privilege.name);
            })
        }
        else {
            PrivilegeChoice = [];
        }

        this.setState({ PrivilegeChoice });
    }

    handleMenuCheckboxChange(menu, checked, isParentMenu) {
        let { menuItemsArray } = this.state;
        if (isParentMenu) {
            if (checked) {
                if (!menuItemsArray.includes(menu.key)) {
                    menuItemsArray.push(menu.key);
                }

                (menu.submenu || []).map(m => {
                    if (!menuItemsArray.includes(m.key)) {
                        menuItemsArray.push(m.key);
                    }
                })
            }

            else {
                menuItemsArray = menuItemsArray.filter(item => item !== menu.key);

                (menu.submenu || []).map(m => {
                    menuItemsArray = menuItemsArray.filter(item => item !== m.key);
                })
            }
        }

        // if submenu
        else {
            if (checked) {
                if (!menuItemsArray.includes(menu.key)) {
                    menuItemsArray.push(menu.key);
                }
            }
            else {
                menuItemsArray = menuItemsArray.filter(item => item !== menu.key);
            }
        }

        this.setState({ menuItemsArray });
    }

    isMenuChecked(menu) {
        let { menuItemsArray } = this.state;
        if (menuItemsArray.includes(menu)) {
            return true;
        }
        return false;
    }

    closePrivilegeCard() {
        this.setState({ isOpenPrivilegeCard: false });
    }

    closeMenuCard() {
        this.setState({ isOpenMenuCard: false });
    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const PrivilegeChoice = this.state.PrivilegeChoice;
        const validationError = this.state.validationError;
        const { formErrors } = this.state;
        /*const PrivilegeMenu = (
            <Menu onClick={this.handlePrivilegeMenuClick}>
                {(this.state.PrivilegeOptions || []).map((privilege) =>
                    <Menu.Item key={privilege.id}>
                        {privilege.name}
                    </Menu.Item>
                )}
            </Menu>
        );*/
        return (
            <div>
                <Card title={this.props.role.id ? "Edit role" : "Add role"}>
                    <Form onSubmit={(e) => this.handleSubmit(e, 'Role')}>
                        <Form.Item label="Role" className="formitem">
                            {getFieldDecorator('name', {
                                initialValue: this.props.role.name,
                                rules: [{ required: true, message: 'Please input Role Name!' }],
                            })(
                                <Input
                                    type="text"
                                    disabled={this.props.role.id}
                                    placeholder="Role Name" />
                            )}
                        </Form.Item>
                        <br /><br />
                        <div>
                            <Button type="primary" htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
                                Save
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            <Button type="secondary" className="user-form-button" onClick={this.close}>
                                Close
                            </Button>
                            <br />{formErrors.role && <Text type="danger">{formErrors.role}</Text>}
                        </div>

                    </Form>
                </Card>

                <br />

                {this.props.role.id && this.state.isOpenPrivilegeCard ?
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Privilege" key="1">
                            <Form onSubmit={(e) => this.handleSubmit(e, 'Privilege')}>
                                <Form.Item label="Select privileges" className="formitem">
                                    {getFieldDecorator('privileges', {
                                        rules: [{ required: true, message: 'Add Privileges as per Role!' }],
                                    })(
                                        <div>
                                            <Checkbox onChange={(e) => this.selectAll(e.target.checked)}>Select
                                                all</Checkbox>
                                            <Row>
                                                {(this.state.PrivilegeOptions || []).map((privilege) =>
                                                    <Col span={24}>

                                                        <Checkbox value={privilege.name}
                                                            checked={this.isChecked(privilege.name)}
                                                            onChange={(e) => this.handleCheckboxChange(privilege.name, e.target.checked)}>
                                                            {privilege.name}
                                                        </Checkbox>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    )}
                                </Form.Item>
                                <br /><br />
                                <div>
                                    <Button type="primary" htmlType="submit"
                                        disabled={this.hasErrors(getFieldsError())}>
                                        Save
                                    </Button>
                                    <span>&nbsp;&nbsp;</span>
                                    <Button type="secondary" className="user-form-button"
                                        onClick={this.closePrivilegeCard}>
                                        Close
                                    </Button>
                                    <br />{formErrors.privileges && <Text type="danger">{formErrors.privileges}</Text>}
                                </div>

                            </Form>
                        </Panel>
                    </Collapse> : null}

                <br />

                {this.props.role.id && this.state.isOpenMenuCard ?
                    <Collapse defaultActiveKey={['2']}>
                        <Panel header="Menu items" key="2">
                            <Form onSubmit={(e) => this.handleSubmit(e, 'Menu')}>

                                <Form.Item label="Select menu" className="formitem" color="blue">
                                    {getFieldDecorator('menuArray', {
                                        initialValue: this.props.role.menuArray || [],
                                        rules: [{ required: true, message: 'Please select menu!' }],
                                    })(
                                        <div>
                                            <Row>
                                                {(this.props.menuArray || []).map((menu, i) =>
                                                    <Col span={24} key={i}>

                                                        <Checkbox value={menu.key}
                                                            checked={this.isMenuChecked(menu.key)}
                                                            onChange={(e) => this.handleMenuCheckboxChange(menu, e.target.checked, true)}>
                                                            {menu.title}
                                                        </Checkbox>

                                                        {(menu.submenu || []).map((subMenuItem, index) =>
                                                            <div style={{ marginLeft: '15%' }} key={index}>
                                                                <Checkbox value={subMenuItem.key}
                                                                    checked={this.isMenuChecked(subMenuItem.key)}
                                                                    onChange={(e) => this.handleMenuCheckboxChange(subMenuItem, e.target.checked, false)}>
                                                                    {subMenuItem.title}
                                                                </Checkbox>
                                                            </div>
                                                        )}

                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    )}
                                </Form.Item>
                                <br /><br />
                                <div>
                                    <Button type="primary" htmlType="submit"
                                        disabled={this.hasErrors(getFieldsError())}>
                                        Save
                                    </Button>
                                    <span>&nbsp;&nbsp;</span>
                                    <Button type="secondary" className="user-form-button" onClick={this.closeMenuCard}>
                                        Close
                                    </Button>
                                    <br />{formErrors.menu && <Text type="danger">{formErrors.menu}</Text>}
                                </div>

                            </Form>
                        </Panel>
                    </Collapse> : null}
            </div>
        );
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    search(privilegeId, Array) {
        for (let i = 0; i < Array.length; i++) {
            if (Array[i].id === privilegeId) {
                return true;
            }
        }
    }

    handlePrivilegeMenuClick(choice) {

        if (!this.state.PrivilegeChoice.includes(choice.item.props.children, 0)) {
            this.setState(prevState => ({
                PrivilegeChoice: [...prevState.PrivilegeChoice, choice.item.props.children]
            }))
        }
    }


    getAllPrivileges() {
        RoleService.getAllPrivilegeTypes().then(response => {
            let PrivilegeOptions = response.data;
            PrivilegeOptions = CommonService.getSorted(PrivilegeOptions || [], 'name', true);

            this.setState({ PrivilegeOptions });
        }).catch(error => {
            notification.open({
                message: 'Something went wrong ',
                discription: error
            });
        })
    }

    removePrivilegeChoice(index) {
        const PrivilegeChoice = Object.assign([], this.state.PrivilegeChoice)
        PrivilegeChoice.splice(index, 1)
        this.setState({ PrivilegeChoice: PrivilegeChoice })
    }
}
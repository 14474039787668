import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "antd";
import { Typography } from 'antd';
import ParkingManagementService from "../../services/ParkingManagementService";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";

const { Text, Title } = Typography;
const {Column} = Table;


export default function ParkingCompaniesView() {

    const [buildings, setBuildings] = useState([])
    const [visible, setVisible] = useState(false);
    const [company, setCompany] = useState([])

    const handleClick = (id)=>{
        setCompany(buildings.filter(building =>
                 building.id === id
        ))
        setVisible(true);
        }

        const handleCancel =()=>{
            setVisible(false)
        }
        
        const column = [ 
            {
                title: "Building",
                dataIndex: "name",
                width:400,
            },
            {
                title: "Total",
                dataIndex: "total",
                width:250,
                render: (text, record) => {
                     return(
                        <>
                    <span>{(record.reservedSlots + "/" + record.visitorSlots)}</span>
                    </>
                     )
                }      
            },
            {
                title: "Free",
                dataIndex: "free",
                width:250,
                render: (text, record) => {
                    return(
                       <>
                   <span>{record.freeSlots + "/" + record.visitorFreeSlots}</span>
                   </>
                    )
               } 
            },
            {
                title: "Busy",
                dataIndex: "busy",
                width:250,
                render: (text, record) => {
                    return(
                       <>
                   <span>{record.filledSlots + "/" + record.visitorFilledSlots}</span>
                   </>
                    )
               } 
            },
            {
                title: "Company",
                dataIndex: "company",
                render: (text, record) => {
                    return(
                       <>
                   <Button type="primary" onClick={()=>handleClick(record.id)}>Company Info</Button> 
                   </>
                    )
               } 
            }
        ]

    useEffect(() => {
        fetchDashboard()
        setInterval(() => {
            fetchDashboard()
        }, 3000);
    }, [])

    function fetchDashboard() {
        ParkingManagementService.getBuildings().then(response => {
            setBuildings(response.data)
        })
    }

    return (
    <div>
              <Heading>Parking Dashboard</Heading>
          <Timer/>
        <Table pagination={false} dataSource={buildings} columns={column}>      
      
        </Table>
        <Modal
        width="1000px"
          title="Companies"
          style={{textAlign:"center"}}
          visible={visible}
          footer={null}
          onCancel={handleCancel}
        >
        <Table pagination={false} dataSource={company[0] ? company[0].companies : ""} >
        <Column title="Company Name" dataIndex="code" key="companyName" /> 
        <Column title="Total" dataIndex="reservedSlots" key="companyTotal" /> 
        <Column title="Free" dataIndex="freeSlots" key="companyFree" /> 
        <Column title="Filled" dataIndex="filledSlots" key="companyFilled" /> 
        </Table>
        </Modal>
        </div>
    )

}
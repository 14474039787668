import React, { Component } from "react";
import {
    Collapse,
    Empty,
    Table,
    message, Input, Typography, Card
} from 'antd';

import Moment from "react-moment";
import queueManagementService from "../../services/QueueManagementService";
import Heading from "../../components/Heading";
import Timer from "../../components/Timer";

const { Paragraph } = Typography;
const { Column } = Table;
const { Panel } = Collapse;

export default class QueuesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            events: {},
            filter: {
                page: 1,
                pageSize: 24
            },
            pageSizeOptions: [12, 24, 48, 96]
        };

        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.refresh();
    }

    onPageSizeChange(current, pageSize) {
        let filter = this.state.filter;
        filter.page = current;
        filter.pageSize = pageSize;
        this.refresh(filter);
    }

    refresh(filter) {
        if (!filter) {
            filter = this.state.filter;
        }
        this.setState({ loading: true });
        queueManagementService.getQueueEvents(filter).then(response => {
            let data = response.data;
            filter.pageSize = data.pageSize;
            filter.currentPage = data.pageNumber;
            filter.totalPages = data.totalPages;
            this.setState({ filter, loading: false, events: data.list });
        }).catch(error => {
            message.error("Error, something went wrong!!")
        })
    }

    onPageChange(page, pageSize) {
        let filter = this.state.filter;
        filter.page = page;
        filter.pageSize = pageSize;
        this.refresh(filter)
    }

    archiveQueue(id) {
        queueManagementService.archiveQueue(id).then(() => {
            this.refresh();
        })

    }

    handleChange(key, change) {
        let filter = this.state.filter;
        switch (key) {
            case "queueName":
                filter.queueName = change;

        }
        this.setState({ filter: filter })
    }

    render() {
        let queueName = this.state.filter.queueName;
        return (
            <div>
               <Heading>Queue Management</Heading>
               <Timer/>
                <Collapse bordered={true} defaultActiveKey={['1']}>
                    <Panel header="Filter" key="1">
                        Queue: <Input value={queueName} style={{ "width": "200px" }}
                            onChange={(e) => this.handleChange("queueName", e.target.value)} /> <br /><br />
                    </Panel>
                </Collapse>
                <div>
                    {this.renderTable()}
                </div>
            </div>
        );
    }

    renderTable() {

        if (this.state.loading || !this.state.events || this.state.events.length === 0) {
            return <Empty description={false} />
        }
        let events = this.state.events;
        let count = this.state.filter.totalPages * this.state.filter.pageSize;
        const paginationOptions = {
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: this.onPageSizeChange,
            onChange: this.onPageChange,
            total: count,
            pageSizeOptions: this.state.pageSizeOptions
        };
        const pagination = {
            ...paginationOptions,
            total: count,
            current: this.state.filter.currentPage,
            pageSize: this.state.filter.pageSize
        };
        return (<div>
            <br />
            <Card title={"All Visits"}>
                <Table dataSource={events} pagination={pagination}>
                    <Column title="ID" dataIndex="eventId" key="eventId"
                        render={eventId => <span>{eventId}</span>} />
                    <Column title="Name" dataIndex="queue.name" key="queue.name"
                        render={(queueName, record) => <span>{record.queue.name}</span>} />
                    <Column title="Date" dataIndex="eventStartDate" key="eventStartDate"
                        render={eventStartDate => (<Moment format="L">{eventStartDate}</Moment>)} />
                    <Column title="Visit Time" dataIndex="duration" key="duration"
                        render={duration => (<span>{duration} min</span>)} />
                </Table>
            </Card>
        </div>
        );

    }
}
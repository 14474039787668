import React, { useState, useEffect } from 'react'
import { Card, Button, Select, Popover, Row, Col, Typography, message, PageHeader, Input, Form } from 'antd'
import UserService from "../services/UserService"
import GenerateTokenService from "../services/GenerateTokenService"
import Heading from '../components/Heading'
import Timer from '../components/Timer'

const { Paragraph } = Typography
const { Option } = Select

export default function GenerateToken() {

    const [selectedUser, changeSelectedUser] = useState(0)
    const [selectedRole, changeSelectedRole] = useState(null)
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [token, setToken] = useState("")
    const [days, setDays] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [isGenerateLoading, setIsGenerateLoading] = useState(false);

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = () => {
        UserService.getUsers()
            .then(res => {
                setUsers(res.data)
            })
            .catch(e => {
                console.log(e)
                message.error("Something Went Wrong")
            })
    }

    const fetchRoles = userId => {
        UserService.getRoles(userId)
            .then(res => {
                setRoles(res.data)
                changeSelectedRole(res.data[0] ? res.data[0].id : null);
            })
            .catch(e => {
                console.log(e)
                message.error("Something Went Wrong")
            })
    }

    const generateToken = () => {

        let formErrors = {};
        let valid = true;
        if (!selectedUser) {
            formErrors.user = "Please select user";
            valid = false;
        }

        if (!selectedRole) {
            formErrors.role = "Please select role";
            valid = false;
        }

        if (!days || days === 0 || days === '0') {
            formErrors.days = "Please enter days";
            valid = false;
        }

        setFormErrors(formErrors);

        if (valid) {
            setFormErrors({});
            setIsGenerateLoading(true);
            GenerateTokenService.generateToken(selectedUser, selectedRole, days)
                .then(res => {
                    const tokenString = JSON.stringify(res.data)
                    setToken(tokenString)
                    setIsGenerateLoading(false);
                })
                .catch(e => {
                    console.log(e)
                    setIsGenerateLoading(false);
                    message.error("Something Went Wrong")
                })
        }
    }

    const hidePopUp = visible => {
        setToken(visible)
    }

    const handleUserChange = userId => {
        changeSelectedUser(userId);
        changeSelectedRole(null);
        fetchRoles(userId);
    }

    return (
        <div>
          <Heading>Generate Token</Heading>
          <Timer/>
            <PageHeader
                className="site-page-header"
            // title="Generate Token"
            />


            <Row>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 4 }} sm={{ span: 2 }} xs={{ span: 2 }} />
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>

                    <Card>
                        <Form
                            layout="vertical"
                        >
                            <Form.Item label="Select user" name="selectedUser" required
                                tooltip="This is a required field">
                                <Select onChange={userId => handleUserChange(userId)}>
                                    {users.map(user => <Option value={user.id}>{user.fullName}</Option>)}
                                </Select>
                                <div className="text-danger">{formErrors.user}</div>
                            </Form.Item>
                            <Form.Item label="Select role" required tooltip="This is a required field">
                                <Select value={selectedRole}
                                    onChange={roleId => changeSelectedRole(roleId)}>
                                    {roles.map(role => <Option value={role.id}>{role.name}</Option>)}
                                </Select>
                                <div className="text-danger">{formErrors.role}</div>
                            </Form.Item>
                            <Form.Item
                                label="Enter days" required tooltip="This is a required field"
                            >
                                <Input
                                    name={'days'}
                                    type="number"
                                    placeholder="Enter no. of days"
                                    value={days}
                                    onChange={e => setDays(e.target.value)}
                                />
                                <div className="text-danger">{formErrors.days}</div>
                            </Form.Item>
                            <div align={"center"}>
                                <Popover
                                    onVisibleChange={hidePopUp}
                                    visible={token}
                                    trigger={"click"}
                                    content={<Paragraph copyable>{token}</Paragraph>}>
                                    <Button type={"primary"} onClick={generateToken} loading={isGenerateLoading}>Generate</Button>
                                </Popover>
                            </div>
                        </Form>

                    </Card>
                </Col>
            </Row>
        </div>
    )

}
import axios from "./axios";
import {authHeader} from "../helpers/auth-header";
import {config} from "../helpers/config";

class AnprService {

    static Instance() {
        return new AnprService()
    }

    fetchSpeedSettings() {
        return axios.get("/api/anpr/speedSettings")
    }

    saveSpeedSettings(req) {
        return axios.put("/api/anpr/speedSettings", req)
    }

    getEvents(filter) {
        return axios.post('/api/anpr/events', filter);
    }

    getIncidents(filter) {
        return axios.post('/api/anpr/incidents', filter);
    }

    getIncidentsHotlisted(filter) {
        return axios.post('/api/anpr/incidents/hotListed', filter);
    }

    archiveEvent(event) {
        return axios.delete('/api/anpr/' + event.id);
    }

    archiveAllEvent(lpr) {
            return axios.put('/api/anpr/events/archive/' + lpr);
        }

    updateEvent(event) {
        return axios.put('/api/anpr/event', event);
    }

    getEventFile(id) {
        return axios.get('/public/anpr/anprText/' + id + '/image.jpg');
    }

    getHelmetMissingIncidentsRepeated(filter) {
            return axios.post('/api/incident/repeated/helmet-missing', filter);
    }
    getReverseDirectionIncidentsRepeated(filter) {
            return axios.post('/api/incident/repeated/reverse', filter);
    }
    getBriefIncidentsRepeated(filter) {
                return axios.post('/api/incident/timeline', filter);
    }
     getIncidentTimeline(filter) {
                     return axios.post('/api/anpr/events/list/bylpr', filter);
     }
     getIncidentsList(filter) {
                    return axios.post('/api/anpr/events/list/lpr/count', filter);
     }

    getAnprSettings() {
        return axios.get("/api/anpr/settings");
    }

    saveAnprSettings(req) {
        return axios.post("/api/anpr/settings", req);
    }

    downloadVehicleImage(id){
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: config.apiUrl + 'public/anpr/vehicle/' + id + "/image.jpg"
        };
        return axios(request);
    }

    downloadLPRImage(id){
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: config.apiUrl + 'public/anpr/lpr/' + id + "/image.jpg"
        };
        return axios(request);
    }

    downloadDriverImage(id){
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: config.apiUrl + 'public/anpr/driver/' + id + "/image.jpg"
        };
        return axios(request);
    }

    downloadDriverImage2(id){
        const request = {
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
            timeout:600000,
            url: config.apiUrl + 'public/anpr/driver/' + id + "/image2.jpg"
        };
        return axios(request);
    }
}

export default AnprService.Instance()
import React, { Component } from 'react'
import { Button, Card, Input, Upload, message, Col, Row, PageHeader, Form } from "antd"
import { UploadOutlined } from '@ant-design/icons'
import OrgService from "../../services/OrgService";
import CommonService from "../../services/CommonService";
import Heading from '../../components/Heading';
import Timer from '../../components/Timer';

export default class EditCurrentOrg extends Component {

    constructor(props) {
        super(props);

        this.state = {
            org: {
                id: 0,
                name: "",
                legalName: "",
                logoFile: "",
                logoFileName: "",
                savingOrgDetails: false,
                savingOrgLogo: false,
            }
        }
        this.refresh = this.refresh.bind(this);
        this.saveOrgDetails = this.saveOrgDetails.bind(this);
        this.saveOrgLogo = this.saveOrgLogo.bind(this);
    }

    componentDidMount() {
        this.refresh()
    }

    refresh(){
        this.loadCurrentOrg()
    }

    loadCurrentOrg = async () => {
        try {
            const res = await OrgService.getOrgDetails()
            const org = { ...res.data }
            this.setState({ org })
        } catch (e) {
            message.error(CommonService.handleErrorMessage(e));
            console.log(e)
        }
    }


    validate = () => {
        let valid = true
        let error = ""
        if (this.state.org.name.length < 3 || this.state.org.name.length > 30) {
            error = "Org Name should be between 3 to 30 charcaters"
            valid = false
        } else if (this.state.org.legalName.length < 3 || this.state.org.legalName.length > 30) {
            error = "Legal Name should be between 3 to 30 charcaters"
            valid = false
        }
        if (!valid) message.error(error)
        return valid
    }

    validateUpload = logoFile => {
        if (logoFile.size / 1024 > 50) {
            message.error("FIle size should be less than 50kb")
        } else {
            const org = { ...this.state.org, logoFile }
            this.setState({ org }, () => {
                message.success("File Verified")
            })
        }
        return false
    }

    saveOrgDetails(e) {
        e.preventDefault();
        if (this.validate()) {
            const formData = new FormData();
            formData.append("id", this.state.org.id)
            formData.append("name", this.state.org.name)
            formData.append("legalName", this.state.org.legalName)

            this.setState({ savingOrgDetails: true });
            OrgService.saveOrgDetails(formData)
                .then(res => {
                    const org = { ...res.data }
                    this.setState({ org, savingOrgDetails: false });
                    message.success("Org Details Saved !")

                })
                .catch(e => {
                    console.log(e);
                    this.setState({ savingOrgDetails: false });
                    message.error(CommonService.handleErrorMessage(e));
                })

        }
    }

    saveOrgLogo() {
        if (!this.state.org.logoFile) {
            message.error("No file found");
            return;
        }

        const formData = new FormData();
        formData.append("id", this.state.org.id)
        formData.append("logoFile", this.state.org.logoFile)

        this.setState({ savingOrgLogo: true });

        OrgService.saveOrgLogo(formData)
            .then(res => {
                const org = { ...res.data };
                this.setState({ org, savingOrgLogo: false });
                message.success("Org Logo Saved !")
            })
            .catch(e => {
                this.setState({ savingOrgLogo: false });
                message.error(CommonService.handleErrorMessage(e));
            })

    }

    handleNameChange = e => {
        const name = e.target.value
        const org = { ...this.state.org, name }
        this.setState({ org })
    }

    handleLegalNameChange = e => {
        const legalName = e.target.value
        const org = { ...this.state.org, legalName }
        this.setState({ org })
    }

    render() {

        const UploadButtonContent =
            this.state.org.id ?
                <img src={"/public/org/logo/" + this.state.org.id} alt={"current org logo"} style={{
                    width: '100%',
                    height: 'auto'
                }} />
                :
                <Button><UploadOutlined /></Button>

        return (
            <div>
               <Heading >Organisation Details</Heading>
               <Timer/>
                <PageHeader
                    className="site-page-header"
                // title="Organisation Details"
                />

                <Card bordered={false}>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={22}>
                                    <Form onSubmit={this.saveOrgDetails} className="org-form">
                                        <Form.Item label="Organisation Name" className="formitem">
                                            <Input
                                                type="text"
                                                placeholder="Enter Organisation Name"
                                                value={this.state.org.name}
                                                onChange={(e) => this.handleNameChange(e)}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Organisation Legal Name" className="formitem">
                                            <Input
                                                type="text"
                                                placeholder="Enter Legal Name"
                                                value={this.state.org.legalName}
                                                onChange={(e) => this.handleLegalNameChange(e)}
                                            />
                                        </Form.Item>
                                        <br />
                                        <div>
                                            <Button type="primary" htmlType="submit" className="org-form-button"
                                                loading={this.state.savingOrgDetails}>
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>

                        </Col>
                        <Col span={12} className="border-left">
                            <Card type={"small"} bordered={false}>
                                <Upload
                                    accept={'image/*'}
                                    showUploadList={false}
                                    beforeUpload={this.validateUpload}
                                >
                                    {UploadButtonContent}
                                </Upload>
                                <br />
                                <br />
                                <br />
                                <br />
                                <Button type={"primary"} onClick={() => this.saveOrgLogo()}
                                    loading={this.state.savingOrgLogo}>
                                    Upload and Save Logo
                                </Button>
                            </Card>
                        </Col>
                    </Row>

                </Card>
            </div>
        )
    }

}